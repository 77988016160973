import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IResponse } from '../interfaces';
import { DocumentModel, LinkToDocumentModel } from '../model/index';

const apiDocumentUrl = 'api/documentation';
@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(private api: ApiService) {}

  public createOrEditDocument(document: DocumentModel): Promise<IResponse<number>> {
    return this.api.post<number>(apiDocumentUrl + '/add', document).toPromise();
  }
  public getByLang(lang: string): Promise<IResponse<DocumentModel[]>> {
    return this.api.get<DocumentModel[]>(apiDocumentUrl + '/getByLang/' + lang).toPromise();
  }
  public deleteDocument(id: number): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(apiDocumentUrl + '/delete/' + id).toPromise();
  }
  public getDocumentById(id: number): Promise<IResponse<DocumentModel>> {
    return this.api.get<DocumentModel>(apiDocumentUrl + '/getById/' + id).toPromise();
  }

  public getDocumentUrlByLang(lang: string): Promise<IResponse<LinkToDocumentModel[]>> {
    return this.api
      .get<LinkToDocumentModel[]>(apiDocumentUrl + '/getDocumentUrlByLang/' + lang)
      .toPromise();
  }
}
