import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetPassComponent } from './reset-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ResetPassComponent],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, SharedModule, TranslateModule],
  exports: [ResetPassComponent],
})
export class ResetPassModule {}
