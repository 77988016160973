export enum TransactionType {
  All = -200,

  Default = 0,

  Receive = 1,

  Send = 2,

  Buy = 3,

  Sell = 4,

  DividendWithdrawal = 29,

  WithdrawSection = 32,

  Compensation = 33,

  WithdrawTokenDividends = 50,

  AirInsurancePayment = 53,

  Stake = 52,
}
