import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs';
import { IResponse } from '../interfaces';
import { environment } from './../../../environments/environment';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  public getAnonym<T>(url: string): Observable<IResponse<T>> {
    return this.http.get<IResponse<T>>(apiUrl + url);
  }

  public get<T>(url: string): Observable<IResponse<T>> {
    return this.http.get<IResponse<T>>(apiUrl + url, { headers: this.getAuthorizationHeaders() });
  }

  public getForPreview(url: string): Observable<any> {
    return this.http.get(apiUrl + url, {
      headers: this.getAuthorizationHeaders(),
      responseType: 'text',
    });
  }

  public delete<T>(url: string): Observable<IResponse<T>> {
    return this.http.delete<IResponse<T>>(apiUrl + url, {
      headers: this.getAuthorizationHeaders(),
    });
  }

  public postAnonym<T>(url: string, body: any): Observable<IResponse<T>> {
    return this.http.post<IResponse<T>>(apiUrl + url, body, { headers: this.getPublicHeaders() });
  }

  public post<T>(url: string, body: any): Observable<IResponse<T>> {
    return this.http.post<IResponse<T>>(apiUrl + url, body, {
      headers: this.getAuthorizationHeaders(),
    });
  }

  public putAnonym<T>(url: string, body: any): Observable<IResponse<T>> {
    return this.http.put<IResponse<T>>(apiUrl + url, body);
  }

  public put<T>(url: string, body: any): Observable<IResponse<T>> {
    return this.http.put<IResponse<T>>(apiUrl + url, body, {
      headers: this.getAuthorizationHeaders(),
    });
  }

  public upload<T>(url: string, body: any): Observable<IResponse<T>> {
    return this.http.post<IResponse<T>>(apiUrl + url, body, {
      headers: this.getAuthorizationFileHeaders(),
    });
  }

  public uploadImage<T>(url: string, body: any, image: any) {
    if (!!body) {
      body.file = image;
    }

    const formData: FormData = new FormData();
    formData.append('file', image);

    return this.http.put<IResponse<T>>(apiUrl + url, formData, {
      params: body,
      headers: this.getAuthorizationFileHeaders(),
    });
  }

  public uploadAvatar<T>(url: string, body: any): Observable<IResponse<T>> {
    const formData = new FormData();

    formData.append('file', body);

    return this.http.put<IResponse<T>>(apiUrl + url, formData, {
      headers: this.getAuthorizationFileHeaders(),
    });
  }

  private getPublicHeaders() {
    var confirmCode = localStorage.getItem('confirmCode');
    var tgData = localStorage.getItem('telegramAuthData');
    var captcha = (<any>window).recaptcha_code;
    var ver = '2';
    if (confirmCode) localStorage.removeItem('confirmCode');
    if (tgData) {
      return {
        'Content-Type': 'application/x-www-form-urlencoded',
        Localization: localStorage.getItem('localization') ?? 'en',
        'Telegram-Auth-Data': tgData,
        Version: ver,
        'Confirmation-Code': confirmCode ?? 'not_set',
      };
    } else {
      return {
        'Content-Type': 'application/json',
        Localization: localStorage.getItem('localization') ?? 'en',
        Version: ver,
        'Confirmation-Code': confirmCode ?? 'not_set',
        Captcha: captcha ?? 'not_set',
      };
    }
    return null;
  }

  private getAuthorizationHeaders(): any {
    let accessToken = localStorage.getItem('accessToken');
    var ver = '2';
    if (accessToken) {
      return {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        Localization: 'en',
        Version: ver,
      };
    }
    return null;
  }

  private getAuthorizationFileHeaders(): any {
    let accessToken = localStorage.getItem('accessToken');
    var ver = '2';
    if (accessToken) {
      return {
        Authorization: `Bearer ${accessToken}`,
        Localization: 'en',
        Version: ver,
      };
    }
    return null;
  }
}
