<div class="auth-block mt-5">
  <p *ngIf="envName == 'test'" class="auth-bg-title text-danger align-center-900 text-uppercase">
    {{ 'Test environment' | lang }}
  </p>
  <p class="auth-bg-title align-center-900">{{ 'REGISTRATION' | lang }}</p>
  <div class="card-body mt-4">
    <form class="theme-form" [formGroup]="registerForm">
      <div class="form-group">
        <input
          class="form-control"
          formControlName="firstName"
          placeholder="{{ 'First name' | lang }}"
        />
        <div *ngIf="elemIsInvalid(firstName)" class="text text-danger mt-1">
          {{ textErrorStr(firstName, namePattern) | lang }}
        </div>
      </div>
      <div class="form-group">
        <input
          class="form-control"
          formControlName="lastName"
          placeholder="{{ 'Last name' | lang }}"
        />
        <div *ngIf="elemIsInvalid(lastName)" class="text text-danger mt-1">
          {{ textErrorStr(lastName, namePattern) | lang }}
        </div>
      </div>
      <div class="form-group">
        <input
          class="form-control"
          formControlName="phone"
          placeholder="{{ 'Phone number' | lang }}"
        />
        <div *ngIf="elemIsInvalid(phone)" class="text text-danger mt-1">
          {{ textErrorStr(phone, phonePattern) | lang }}
        </div>
      </div>
      <div class="form-group">
        <input
          type="email"
          placeholder="{{ 'Email' | lang }}"
          class="form-control"
          formControlName="email"
        />
        <div *ngIf="elemIsInvalid(registerForm.controls.email)" class="text text-danger mt-1">
          {{ textErrorStr(registerForm.controls.email, emailPattern) | lang }}
        </div>
      </div>

      <div class="form-group">
        <input
          class="form-control"
          formControlName="password"
          [type]="!showPass ? 'password' : 'text'"
          placeholder="{{ 'Password' | lang }}"
        />
        <div *ngIf="elemIsInvalid(registerForm.controls.password)" class="text text-danger mt-1">
          {{ textErrorStr(registerForm.controls.password, latinAndNumberPattern) | lang }}
        </div>
      </div>

      <div class="form-group">
        <input
          placeholder="{{ 'Confirm password' | lang }}"
          class="form-control"
          [type]="!showPass ? 'password' : 'text'"
          formControlName="passwordConfirm"
        />
        <div
          *ngIf="elemIsInvalid(registerForm.controls.passwordConfirm)"
          class="text text-danger mt-1"
        >
          {{ textErrorStr(registerForm.controls.passwordConfirm) | lang }}
        </div>
      </div>

      <div class="form-check">
        <input id="checkbox" type="checkbox" formControlName="agreement" class="form-check-input" />
        <label
          for="checkbox"
          class="ps-2 f-12 align-top pt-1"
          [innerHtml]="agreementText | lang"
        ></label>

        <div *ngIf="elemIsInvalid(registerForm.controls.agreement)" class="text text-danger mt-1">
          {{ textErrorStr(registerForm.controls.agreement) | lang }}
        </div>
      </div>

      <div class="form-group pt-2">
        <button class="btn btn-primary btn-block" (click)="registration()">
          {{ 'Sign up' | lang }}
        </button>
      </div>

      <!-- <div class="row px-3">
        <div class="col login-divider"></div>
        <div class="col-auto login-divider-text text-uppercase">{{'or'| lang}}</div>
        <div class="col login-divider"></div>
      </div>

      <div class="form-group">
        <div class="telegram-reg-widget" id="telegramAuthWidget" #telegramAuthWidget></div>
      </div> -->

      <div class="auth-card-footer">
        {{ 'Already have an account' | lang }}?
        <a href="javascript:void(0)" (click)="navigateTab('login')">{{ 'Sign in' | lang }}</a>
      </div>
    </form>
  </div>
</div>
