export var menuitems: { [key: string]: string } = {
  dashboard: 'assets/images/tta/menuitems/dashboard.svg',
  wallet: 'assets/images/tta/menuitems/wallet.svg',
  documents: 'assets/images/tta/menuitems/documents.svg',
  users: 'assets/images/tta/menuitems/users.svg',
  settings: 'assets/images/tta/menuitems/settings.svg',
  assets: 'assets/images/tta/menuitems/assets.svg',
  refPayouts: 'assets/images/tta/menuitems/refPayouts.svg',
  comfortTravel: 'assets/images/tta/menuitems/airplane.svg',
  learning: 'assets/images/tta/menuitems/learning.svg',
  stake: 'assets/images/tta/menuitems/stake.svg',
  learningHub: 'assets/images/tta/menuitems/learningHub.svg',
};
