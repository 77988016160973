<div class="auth-block">
  <div class="auth-bg-title align-center-900">{{ 'Forgot password' | lang }}?</div>
  <div class="card-body">
    <label class="login-header align-center-900 my-3">
      {{
        'Enter the email address you used when you created the account, and we’ll send you a code to reset your password'
          | lang
      }}
    </label>
    <form class="theme-form" [formGroup]="forgotPassForm">
      <div class="form-group mx-1 pt-2">
        <input
          type="email"
          class="form-control"
          formControlName="email"
          type="email"
          placeholder="{{ 'Email' | lang }}"
          required=""
          autofocus=""
        />
        <div *ngIf="elemIsInvalid(forgotPassForm.controls.email)" class="text text-danger mt-1">
          {{ textErrorStr(forgotPassForm.controls.email) }}
        </div>
      </div>

      <div class="form-group mx-1">
        <button class="btn btn-primary btn-block" (click)="resetPass()">
          {{ 'Submit' | lang }}
        </button>
      </div>

      <div class="auth-card-footer">
        <a href="javascript:void(0)" (click)="navigateTab('login')">
          {{ 'Back to Sign in' | lang }}
        </a>
      </div>
    </form>
  </div>
</div>
