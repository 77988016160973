import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { permissions } from '../../constants';
import { AuthService, CryptoUserService, SubscriptionService, UserService } from '../../services';
import { NavService, Page } from '../../services/nav.service';
import * as $ from 'jquery';
import { CryptoUserModel, HeaderNotificationModel, NotificationModel } from '../../model';
import { BaseComponent } from '../base/base.component';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { EventService } from '../../services/event.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Subscription } from 'rxjs';
// import jQuery from 'jquery';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends BaseComponent implements OnInit, AfterViewChecked {
  public menuItems: Page[];
  //public isActiveBar: boolean = false;
  public cryptoUser: () => CryptoUserModel;
  public currentYear: number;
  public headerNotification: HeaderNotificationModel = new HeaderNotificationModel();
  currentLang: string;
  public notifEventSubscription: Subscription;

  constructor(
    private router: Router,
    public navService: NavService,
    private userService: UserService,
    private cryptoUserService: CryptoUserService,
    private subscriptionService: SubscriptionService,
    public authService: AuthService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private eventService: EventService,
    private bsLocaleService: BsLocaleService,
  ) {
    super(translate, modalService);

    this.currentYear = new Date().getFullYear();

    var items = environment.buildAdmin ? this.navService.adminItems : this.navService.mainItems;
    items.subscribe((menuItems) => {
      this.menuItems = menuItems;
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter((items) => {
            if (items.path === event.url) {
              //&& !this.isActiveBar
              this.setNavActive(items);
            }

            // Jija в которой сравниваем только базовные части пути
            // при переходе по /my-assets/iTTA, он не найдет подходящий рут, потому что в итемах /my-assets/TTA
            let currentUrl = event.url.split('/');
            let menuItemPath = items.path.split('/');
            if (currentUrl[1] === menuItemPath[1]) {
              this.setNavActive(items);
            }

            if (!items.children) return false;
            items.children.filter((subItems) => {
              if (subItems.path === event.url) {
                //&& !this.isActiveBar
                this.setNavActive(subItems);
              }
              if (!subItems.children) return false;
              subItems.children.filter((subSubItems) => {
                if (subSubItems.path === event.url) this.setNavActive(subSubItems);
              });
            });
          });
        }
      });
    });

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationStart) {
        var sidebar = document.getElementById('sidebar');
        if (sidebar) sessionStorage.setItem('sidebarScrollY', sidebar.scrollTop.toString());
      }
    });

    var t = this;
    t.cryptoUser = () => {
      if (!!t.cryptoUserService.get()) return t.cryptoUserService.get();
      else return new CryptoUserModel();
    };

    t.translate.setDefaultLang('en');
    t.currentLang = localStorage.getItem('localization');
    if (!t.currentLang) t.currentLang = 'en';
    t.translate.use(t.currentLang);
    localStorage.setItem('localization', t.currentLang);

    t.notifEventSubscription = t.eventService.NotifCountEvent.subscribe((data: string) => {
      t.getNotifications(data);
    });
  }

  ngAfterViewChecked(): void {
    var scrollPosition = sessionStorage.getItem('sidebarScrollY');
    if (!!scrollPosition) {
      var sidebar = document.getElementById('sidebar');
      sidebar.scrollTop = +scrollPosition;
    }
  }

  ngOnDestroy() {
    var t = this;
    if (t.notifEventSubscription) {
      t.notifEventSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.getNotifications(this.currentLang);
  }

  toDashboard() {
    this.router.navigate(['/dashboard']);
  }

  openLearningHub() {
    let t = this;
    t.router.navigateByUrl('/bookstore/products');
  }

  // Active Nave state
  setNavActive(item) {
    this.menuItems.filter((menuItem) => {
      if (menuItem != item) menuItem.active = false;
      if (menuItem.path == item.path) {
        menuItem.active = true;
        //return this.isActiveBar = true;
      }
      if (!!menuItem.children) {
        //submenuItems.children && submenuItems.children.includes(item)
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.path == item.path) {
            menuItem.active = true;
            //submenuItems.active = true
          }
        });
        //return this.isActiveBar = true;
      }
    });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    if (!item.active) {
      this.menuItems.forEach((a) => {
        if (this.menuItems.includes(item)) a.active = false;
        if (!a.children) return false;
        a.children.forEach((b) => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
      item.active = !item.active;

      //Зыкрытие навбара при переходе на другие страницы в мобилке
      if (window.innerWidth < 569) this.collapseSidebar();
    }
  }

  needHidden(navRoles: string[]) {
    var t = this;

    if (!t.userService.get()) {
      return true;
    }

    var isHide = false;
    var userRoles = t.userService.getRole();
    //админ по умолчанию имеет доступ ко всем разделам
    if (userRoles.includes(permissions.Master)) {
      return isHide;
    }

    if (!!navRoles) {
      navRoles.forEach((navRole) => {
        if (!userRoles.includes(navRole)) {
          isHide = true;
        }
      });
    }

    return isHide;
  }

  showNotifications() {
    this.navService.showNotif = !this.navService.showNotif;
  }

  collapseSidebar() {
    this.navService.collapseSidebar = !this.navService.collapseSidebar;
  }

  getAvatar(): string {
    var t = this;
    if (t.cryptoUser().avatarUrl) return t.cryptoUser().avatarUrl;
    else return t.defaultAvatarUrl;
  }

  getUserName() {
    let t = this;
    if (t.cryptoUser().firstname && t.cryptoUser().lastname) {
      return `${t.cryptoUser().firstname} ${t.cryptoUser().lastname}`;
    } else return 'No Name';
  }

  getNotifications(lang) {
    var t = this;
    t.subscriptionService
      .getLocalizedNotifications(lang)
      .then((resp) => {
        t.headerNotification = resp.data;
      })
      .catch((er) => {
        // t.showResponseError(er);
      });
  }

  toSettings() {
    this.router.navigate(['user-settings']);

    if (window.innerWidth < 569) this.collapseSidebar();
  }

  toProfile() {
    this.router.navigateByUrl('user-settings');

    if (window.innerWidth < 569) this.collapseSidebar();
  }

  toNotifications() {
    this.router.navigate(['notifications']);

    if (window.innerWidth < 569) this.collapseSidebar();
  }

  getNotificationIconUrl(): string {
    let t = this;
    return t.headerNotification.notCheckedCount > 0
      ? 'assets/images/tta/bell.svg'
      : 'assets/images/tta/bell-unread.svg';
  }

  switchLanguage() {
    let t = this;
    let langToSwitch = t.currentLang == 'ru' ? 'en' : 'ru';
    t.currentLang = langToSwitch;

    localStorage.setItem('localization', langToSwitch);
    t.translate.use(langToSwitch);
    t.bsLocaleService.use(langToSwitch);

    t.getNotifications(langToSwitch);
    t.eventService.LangChangeEvent.emit(langToSwitch);
  }
}
