export class HistorySessionModel {
  date: string;
  email: string;
  oc: string;
  browser: string;
  device: number;
  ipAddress: string;
  frontVersion: string;
  lang: string;
  firstName: string;
  lastName: string;
  systemEthAddress: string;
  fingerPrint: string;
}
