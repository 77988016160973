import {
  Component,
  Input,
  OnInit,
  Output,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2,
} from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService, ApiService, CryptoUserService } from '../../shared/services';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/shared/component/base/base.component';
import { ConfirmModal } from 'src/app/shared/component/modals/confirm';
import { CryptoUserModel } from 'src/app/shared/model';

@Component({
  selector: 'confirmation-code',
  templateUrl: './confirmation-code.component.html',
  styleUrls: ['./confirmation-code.component.scss'],
})
export class ConfirmationCodeComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('confirmCodeBlock') confirmCodeBlock: ElementRef;

  email: string = '';
  password: string = '';
  public currentUserLang: string;
  public confirmCode: FormGroup;
  public currentId: number = 1;
  public accessKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  public typeTwoFactorAuth: number = 0;
  public showErrorText: boolean = false;
  public codeArr: string[] = [];
  public credentials: any;
  public loginCred: any;
  public modalRef: NgbModalRef;
  public runIntervals: any[] = [];
  public getUser: () => CryptoUserModel;
  public receptionType: string = '';

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private cryptoUserService: CryptoUserService,
    private modalService: NgbModal,
    private translate: TranslateService,
  ) {
    super(translate, modalService);
    let t = this;
    t.confirmCode = fb.group({
      input1: ['', [Validators.required]],
      input2: ['', [Validators.required]],
      input3: ['', [Validators.required]],
      input4: ['', [Validators.required]],
      input5: ['', [Validators.required]],
      input6: ['', [Validators.required]],
    });
  }

  get input1() {
    return this.confirmCode.get('input1');
  }
  get input2() {
    return this.confirmCode.get('input2');
  }
  get input3() {
    return this.confirmCode.get('input3');
  }
  get input4() {
    return this.confirmCode.get('input4');
  }
  get input5() {
    return this.confirmCode.get('input5');
  }
  get input6() {
    return this.confirmCode.get('input6');
  }

  ngOnInit() {
    var t = this;
    (document.getElementById(t.currentId.toString()) as HTMLInputElement).select();
    t.typeTwoFactorAuth = t.cryptoUserService.type2FA;
    t.runIntervals.push(setTimeout(t.leave, 5 * 60 * 1000, t)); //выход через 5 минут
    if (t.typeTwoFactorAuth == 0) t.router.navigate(['/auth/login']);
    if (t.typeTwoFactorAuth == -1) {
      t.receptionType = t.cryptoUserService.email;
    } else {
      t.receptionType = 'Google Authenticator';
    }
  }

  public checkPaste(event: any) {
    event.preventDefault();
    var paste = <string>event.clipboardData.getData('text');
    if (paste.match(this.confirm2FAPattern)) {
      var codeArr = paste.split('');
      this.setCode(codeArr);
    }
  }

  public leave(t: any) {
    t.router.navigate(['/auth/login']);
    t.modalRef = t.modalService.open(ConfirmModal, {
      backdropClass: 'light-white-backdrop',
      centered: true,
      size: 'sm',
      windowClass: 'super-modal-delete-users very-nice-shadow',
    });

    t.modalRef.componentInstance.title = t.translate.instant('Warning');
    t.modalRef.componentInstance.showDeclineButton = false;
    t.modalRef.componentInstance.description = t.translate.instant(
      'The time to enter the confirmation code has expired',
    );
    t.modalRef.componentInstance.buttonConfirm = t.translate.instant('OK');
  }

  public forward(value: string) {
    var t = this;
    var current = document.getElementById(t.currentId.toString()) as HTMLInputElement;
    current.value = value;
    if (t.currentId == 6) {
      t.login();
      return;
    }
    t.currentId = t.getId(t.currentId + 1);
    var next = document.getElementById(t.currentId.toString()) as HTMLInputElement;
    next.focus();
  }

  public back(id) {
    var t = this;
    t.currentId = id;
    var current = document.getElementById(t.currentId.toString()) as HTMLInputElement;
    t.currentId = t.getId(t.currentId - 1);
    if (!current.value) {
      var prev = document.getElementById(t.currentId.toString()) as HTMLInputElement;
      prev.value = '';
      prev.focus();
    }
    current.value = '';
  }

  public getId(id: number): number {
    return id > 6 ? 6 : id < 1 ? 1 : id;
  }

  public getCode(): string {
    var t = this;
    var result = '';
    var i = 1;
    for (; i < 7; i++) {
      var current = document.getElementById(i.toString()) as HTMLInputElement;
      result += current.value;
    }
    return result;
  }

  public setCode(arr: string[]) {
    var t = this;
    t.currentId = 1;
    arr.forEach((element) => {
      this.forward(element);
    });
  }

  login() {
    var t = this;
    var code = t.getCode();
    if (code.length < 6) {
      t.showErrorText = true;
      return;
    }

    t.setLoading(true);
    var loginCred;
    localStorage.setItem('confirmCode', code);
    t.cryptoUserService.credentials$.subscribe({
      next(credentials) {
        loginCred = credentials;
      },
    });
    t.authService
      .SignIn(loginCred)
      .then((resp) => {
        t.setLoading(false);
        t.router.navigate(['/dashboard']);
        localStorage.removeItem('telegramAuthData');
      })
      .catch((e) => {
        t.showErrorText = true;
        t.setLoading(false);
      });
  }

  handler(event, id) {
    event.preventDefault();
    var t = this;
    t.currentId = id;
    if (t.accessKeys.indexOf(event.key) != -1) {
      t.forward(event.key);
    } else if (event.key == 'Backspace' || (event.ctrlKey && event.key == 'v')) {
      return;
    }
  }

  ngOnDestroy() {
    var t = this;
    t.cryptoUserService.CleanCredentials();
    t.runIntervals.forEach((element) => {
      clearInterval(element);
    });
  }

  public navigateTab(route: string) {
    var t = this;
    t.router.navigateByUrl('/auth/' + route);
  }
}
