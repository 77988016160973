import { Injectable, EventEmitter } from '@angular/core';
import { SettingMasterModel } from '../model/settingMaster.model';
import { ApiService } from './api.service';
import { IResponse } from '../interfaces';
import { ComfortTravelProgramModel } from '../model/comfortTravelProgram.model';

const apisettingUrl = 'api/setting';
@Injectable({
  providedIn: 'root',
})
export class SettingService {
  constructor(private api: ApiService) {}

  public getLastUpdate(nameSubstring: string): Promise<IResponse<SettingMasterModel[]>> {
    return this.api
      .get<SettingMasterModel[]>(apisettingUrl + '/getlastupdate/' + nameSubstring)
      .toPromise();
  }

  public updateAddressBook(): Promise<IResponse<SettingMasterModel[]>> {
    return this.api.get<SettingMasterModel[]>(apisettingUrl + '/updateAddressBook').toPromise();
  }

  public getAllSetting(lang: string): Promise<IResponse<SettingMasterModel[]>> {
    return this.api.get<SettingMasterModel[]>(apisettingUrl + '/all/' + lang).toPromise();
  }

  public updateSetting(model: SettingMasterModel[]): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(apisettingUrl + '/update', model).toPromise();
  }

  public programRegistration(model: ComfortTravelProgramModel): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(apisettingUrl + '/programRegistration', model).toPromise();
  }
}
