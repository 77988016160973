import { Injectable } from '@angular/core';
import { IResponse } from '../interfaces';
import { BaseFilterModel, HistorySessionModel } from '../model';
import { ApiService } from './api.service';
import { NavigationEnd, Router } from '@angular/router';

const historySessionApiUrl = 'api/historySession';

@Injectable({
  providedIn: 'root',
})
export class HistorySessionService {
  public isSession: boolean;
  public lastSessionParams: HistorySessionModel = null;

  constructor(
    private api: ApiService,
    private router: Router,
  ) {}

  //получение списка устройств через которые юзер зашел
  public getHistorySession(): Promise<IResponse<HistorySessionModel[]>> {
    return this.api.get<HistorySessionModel[]>(historySessionApiUrl + '/getsession').toPromise();
  }

  //отправка ника / email / ip пользователя для поиска истории сессий
  public getHistorySessionAdmin(
    filter: BaseFilterModel,
  ): Promise<IResponse<HistorySessionModel[]>> {
    return this.api
      .post<HistorySessionModel[]>(historySessionApiUrl + '/getsessionadmin', filter)
      .toPromise();
  }

  //обновить значение сессии
  public refreshSession(): Promise<boolean> {
    var t = this;
    const pgClient = new (<any>window).ClientJS();
    var newSession = new HistorySessionModel();
    newSession.browser = pgClient.getBrowser();
    newSession.oc = pgClient.getOS() + ' ' + pgClient.getOSVersion();
    newSession.frontVersion = 'v1';
    newSession.lang = pgClient.getLanguage();
    newSession.fingerPrint = pgClient.getFingerprint().toString();
    t.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && event.urlAfterRedirects === '/auth/login') {
        t.lastSessionParams = null;
      }
    });
    if (
      t.lastSessionParams != null &&
      t.lastSessionParams.browser == newSession.browser &&
      t.lastSessionParams.oc == newSession.oc &&
      t.lastSessionParams.frontVersion == newSession.frontVersion &&
      t.lastSessionParams.lang == newSession.lang &&
      t.lastSessionParams.fingerPrint == newSession.fingerPrint
    ) {
      return;
    }
    t.lastSessionParams = newSession;
    return t.api
      .post<boolean>(historySessionApiUrl + '/session', newSession)
      .toPromise()
      .then((resp) => {
        t.isSession = resp.data;
        return t.isSession;
      });
  }

  // Инициализировать сервис сессии
  public initSession(): Promise<boolean> {
    var t = this;
    var promise: Promise<boolean>;
    if (t.getSession() == undefined || t.getSession() == null) {
      promise = this.refreshSession();
    } else {
      promise = new Promise<boolean>((resolve) => {
        resolve(t.getSession());
      });
    }
    return promise;
  }
  public getSession(): boolean {
    return this.isSession;
  }

  //очистить значение сессии
  public clearSession(): void {
    this.isSession = null;
  }

  //получить параметры последней сохраненной сессии
  public getLastSessionParams(): HistorySessionModel {
    return this.lastSessionParams;
  }

  //обновить параметры последней сохраненной сессии
  public refreshSessionParams(): Promise<HistorySessionModel> {
    var t = this;
    return t.api
      .get<HistorySessionModel>(historySessionApiUrl + '/refreshsessionparams')
      .toPromise()
      .then((resp) => {
        t.lastSessionParams = resp.data;
        return t.lastSessionParams;
      });
  }
}
