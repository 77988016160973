<div class="block-container mx-auto max-w-400 modal-width">
  <div class="row mb-3 t-center" *ngIf="showTitle">
    <div class="col text-uppercase f-20 f-w-700 t-center">{{ title | lang }}</div>
  </div>
  <div class="col">
    <div class="col">
      <div class="row mb-3 text-center text-break" [hidden]="!descriptionHTML">
        <label [innerHtml]="descriptionHTML" class="c-white f-16"></label>
      </div>
      <hr />
      <div class="row justify-content-center text-center">
        <div *ngIf="showDeclineButton" class="col">
          <button
            data-target="#modal-confirm"
            data-toggle="modal"
            class="btn btn-outline-info w-100"
            type="button"
            (click)="isConfirm(false)"
          >
            {{ buttonDecline | lang }}
          </button>
        </div>
        <div *ngIf="showConfirmButton" class="col">
          <button
            data-target="#modal-confirm"
            data-toggle="modal"
            class="btn btn-primary w-100"
            type="button"
            (click)="isConfirm(true)"
          >
            {{ buttonConfirm | lang }}
          </button>
        </div>
        <div *ngIf="showErrorButton" class="col">
          <button
            data-target="#modal-confirm"
            data-toggle="modal"
            class="btn btn-danger"
            type="button"
            (click)="isConfirm(true)"
          >
            {{ buttonError | lang }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
