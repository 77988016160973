<!-- Page Header Start-->
<div class="page-main-header" id="page-main-header">
  <div class="main-header-right row">
    <div class="col-auto" style="padding: 16px 11px 16px 15px">
      <img
        (click)="router.navigateByUrl('dashboard')"
        class="pointer"
        src="assets/images/logo/tta_logo.svg"
        alt=""
      />
    </div>

    <div class="nav-right header-nav-right-custom col">
      <ul class="nav-menus fontsize gap-3" style="float: right">
        <!-- Announcement bar navigation -->
        <li class="desktop-show">
          <div class="block-container announcement-bar">
            <div class="icon-container">
              <img src="assets/images/tta/book-logo.svg" style="width: 12px; height: 14px" alt="" />
            </div>
            <div class="content-title">
              {{ 'The new book from the founder of TriAngels' | lang }}
            </div>
            <button class="btn btn-primary announcement-bar-btn" (click)="openLearningHub()">
              <div class="announcement-bar-btn-text">
                {{ 'buttons.learnMore' | lang }}
              </div>
              <img src="assets/images/tta/icons/arrow_right.svg" alt="" />
            </button>
          </div>
        </li>
        <!-- Announcement bar navigation end-->

        <li (click)="switchLanguage()" class="cursor-pointer desktop-show">
          <div class="lang-switcher" style="width: 60px">
            <div
              [ngClass]="{ active: currentLang == 'ru', inactive: currentLang != 'ru' }"
              class="w-50 t-center"
            >
              РУ
            </div>
            <div
              [ngClass]="{ active: currentLang == 'en', inactive: currentLang != 'en' }"
              class="w-50 t-center"
            >
              EN
            </div>
          </div>
        </li>

        <li class="onhover-dropdown cursor-pointer desktop-show" id="bell">
          <img
            alt=""
            src="{{
              headerNotification.notCheckedCount > 0
                ? 'assets/images/tta/bell.svg'
                : 'assets/images/tta/bell-unread.svg'
            }}"
          />

          <ul
            class="notification-dropdown onhover-show-div custom-scrollbar"
            style="max-height: 300px; overflow: hidden scroll"
          >
            <li *ngFor="let notif of headerNotification.notifications">
              <div *ngIf="!notif.isChecked" class="media" (click)="checkNotification(notif)">
                <hr class="w-100" />
                <div class="media-body">
                  <div class="row my-0">
                    <div class="col-1">
                      <img class="pt-1" src="assets/images/tta/notification.svg" alt="" />
                    </div>
                    <div class="col-10">
                      <label class="f-20 c-yellow w-100">{{ notif.title | lang }}</label>
                      <p
                        *ngFor="let bodyText of notif.body"
                        class="mb-0 text-break"
                        [innerHtml]="bodyText"
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <div
              *ngIf="headerNotification.notCheckedCount > 0"
              class="d-flex gap-1 align-items-center mb-2 mt-2"
            >
              <div class="f-12 c-gray px-1">{{ 'New' | lang }}</div>
              <hr class="w-100" style="background-color: #858585" />
            </div>

            <li *ngFor="let notif of headerNotification.notifications">
              <div *ngIf="notif.isChecked" class="media" (click)="checkNotification(notif)">
                <div class="media-body">
                  <div class="row my-0">
                    <div class="col-1">
                      <img class="pt-1" src="assets/images/tta/notification-inactive.svg" alt="" />
                    </div>
                    <div class="col-10">
                      <label class="f-20 c-white-100">{{ notif.title | lang }}</label>
                      <p
                        *ngFor="let bodyText of notif.body"
                        class="mb-0 text-break"
                        [innerHtml]="bodyText"
                      ></p>
                    </div>
                  </div>
                </div>
                <hr class="w-100" />
              </div>
            </li>

            <li
              *ngIf="!!headerNotification.notifications && !headerNotification.notifications.length"
              class="txt-dark"
            >
              {{ 'No new notifications' | lang }}
            </li>

            <li class="text-center">
              <button
                type="button"
                (click)="checkAllNotifications()"
                [disabled]="headerNotification.notCheckedCount == 0"
                class="btn btn-secondary mt-3 w-100"
              >
                {{ notifButtonText | lang }}
              </button>
            </li>
          </ul>
        </li>

        <li class="desktop-show">
          <div class="media align-items-center">
            <img
              src="assets/images/tta/settings.svg"
              (click)="toSettings()"
              style="cursor: pointer"
              alt=""
            />
          </div>
        </li>
        <li class="onhover-dropdown cursor-pointer desktop-show">
          <div class="media align-items-center">
            <img
              class="avatar-circle-36"
              [src]="avatarUrl"
              [ngClass]="{ invisible: !avatarUrl }"
              alt=""
            />
          </div>
          <ul class="profile-dropdown onhover-show-div p-20">
            <li (click)="authService.SignOut()">
              <img src="assets/images/tta/logout.svg" alt="" />
              <span class="ms-3">{{ 'Logout' | translate }}</span>
            </li>
          </ul>
        </li>
        <li class="mobile-show">
          <img src="assets/images/menuitems/burger.svg" alt="" (click)="collapseSidebar()" />
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
