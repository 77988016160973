import { Injectable } from '@angular/core';
import { IResponse } from '../interfaces';
import { ApiService } from './api.service';
import { HeaderNotificationModel } from '../model';

const subApiUrl = 'api/subscription/';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(private api: ApiService) {}

  //получить уведомления
  public getNotifications(): Promise<IResponse<HeaderNotificationModel>> {
    return this.api.get<HeaderNotificationModel>(subApiUrl + 'getnotifs').toPromise();
  }

  //сделать уведобление прочитанным
  public checkNotification(notifId): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(subApiUrl + 'checknotif/' + notifId).toPromise();
  }

  public checkAllNotifications(): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(subApiUrl + 'checkAllNotifs').toPromise();
  }

  public getLocalizedNotifications(lang: string): Promise<IResponse<HeaderNotificationModel>> {
    return this.api
      .get<HeaderNotificationModel>(subApiUrl + 'getlocalizednotif/' + lang)
      .toPromise();
  }
}
