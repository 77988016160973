export var limitsForPurchases = {
  minForTTA: 10, //в долларах
};

export var limitsForWithdraw = {
  minForTTA: 0.1, //в токенах
};

export var limitsForSell = {
  minForTTA: 2, //в токенах
};
