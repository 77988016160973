<div class="col-12 section-header">
  <div class="row">
    <div class="col">
      <span class="f-44 mf-30">{{ title | lang }}</span>
    </div>
    <div class="col-auto">
      <div *ngIf="showInput">
        <input id="download" type="file" #file class="d-none" (change)="emitUpload(file.files)" />
        <label for="download" class="mb-0 float-end">
          <a class="btn btn-primary">Choose file</a>
        </label>
      </div>
      <button
        *ngIf="!!firstButton && firstButton.buttonTitle && firstButton.needShow"
        class="btn ms-2 desktop-show {{ firstButton.className }}"
        (click)="emitButton(firstButton)"
      >
        {{ firstButton.buttonTitle | lang }}
      </button>
      <button
        *ngIf="!!secondButton && secondButton.buttonTitle && secondButton.needShow"
        class="btn ms-2 desktop-show btn-primary {{ secondButton.className }}"
        (click)="emitButton(secondButton)"
      >
        {{ secondButton.buttonTitle | lang }}
      </button>
    </div>
  </div>
</div>
