export class HeaderButtonModel {
  funcName?: string = '';
  itemTitle: string = '';
  buttonTitle: string = '';
  headerTitle?: string = '';
  needShow?: boolean = true;
  className?: string = '';
  buttonNumber: number = 0;
  url?: string;
}
