import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationCodeComponent } from './confirmation-code.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ConfirmationCodeComponent],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, SharedModule, TranslateModule],
  exports: [ConfirmationCodeComponent],
})
export class ConfirmationCodeModule {}
