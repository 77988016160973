<p *ngIf="envName == 'test'" class="auth-bg-title text-danger align-center-900 text-uppercase">
  {{ 'Test environment' | lang }}
</p>
<p class="auth-bg-title align-center-900">{{ 'Welcome' | lang }}</p>
<div class="card-body">
  <div class="login-header align-center-900 my-3">
    <label>{{ 'L2 solutions on the Ethereum blockchain' | lang }}</label>
  </div>
  <form class="theme-form" [formGroup]="loginForm">
    <div class="form-group pt-2">
      <input
        id="email-input-field"
        type="email"
        class="form-control"
        formControlName="email"
        placeholder="{{ 'Email' | lang }}"
        required=""
        autofocus=""
      />
      <div *ngIf="elemIsInvalid(loginForm.controls.email)" class="text text-danger mt-1">
        {{ textErrorStr(loginForm.controls.email, emailPattern) | lang }}
      </div>
    </div>

    <div class="form-group">
      <div class="input-pass">
        <div style="position: relative">
          <input
            placeholder="{{ 'Password' | lang }}"
            [type]="!showPass ? 'password' : 'text'"
            class="form-control"
            formControlName="password"
          />
          <!-- <img (click)= "hideOrShowPassword()" [src]="showPass? '/assets/images/auth/icon_eye_closed.svg' : '/assets/images/auth/icon_eye.svg'" 
              style = "position: absolute;  top: 13px; right: 13px; height: 22px; opacity: 0.2;" draggable="false"> -->
        </div>
      </div>
      <div *ngIf="elemIsInvalid(loginForm.controls.password)" class="text text-danger mt-1">
        {{ textErrorStr(loginForm.controls.password) | lang }}
      </div>
    </div>
    <div *ngIf="!!errorText" class="text text-danger text-center mb-3">
      <img src="assets/images/danger.svg" alt="" />&nbsp;{{ errorText | lang }}
    </div>
    <div class="form-group">
      <button
        id="login-button"
        class="btn btn-primary btn-block"
        [class.loader--text]="authService.showLoader"
        (click)="login()"
      >
        {{ 'Sign in' | lang }}
      </button>
    </div>
    <!--
    <div class="row px-3">
      <div class="col login-divider"></div>
      <div class="col-auto login-divider-text text-uppercase">{{'or'| lang}}</div>
      <div class="col login-divider"></div>
    </div>

    <-- <div class="form-group mt-3">
      <div class="telegram-reg-widget" id="telegramAuthWidget" #telegramAuthWidget></div>
    </div> ->
    <div class="form-group mt-3" #telegramAuth>
      <button class="btn btn-secondary w-100" (click)="loginTelegram()">
        {{'Continue with Telegram' | lang}}
      </button>
    </div> -->

    <div class="auth-card-footer">
      {{ 'Forgot password' | lang }}?
      <a href="javascript:void(0)" (click)="navigateTab('resetPass')">{{
        'Reset password' | lang
      }}</a>
    </div>

    <div class="auth-card-footer">
      {{ "Don't have an account yet" | lang }}?
      <a href="javascript:void(0)" (click)="navigateTab('reg')">{{ 'Sign up' | lang }}</a>
    </div>
  </form>
</div>
