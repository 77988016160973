import { RegexUtils } from '.';

const ERROR_REQUIRED = 'errors.required';

export class ValidationUtils {
  /**
   * Проверка что фамилия, имя или отчество кашерное.
   * В случае некашерности возвращает IValidationResult с текстом ошибки.
   */
  public static validateName(val: string): IValidationResult {
    if (RegexUtils.isNullOrWhitespace(val)) {
      return {
        isValid: false,
        errorText: ERROR_REQUIRED,
      };
    }

    var latinAndCyrillic = val.replace(/[^a-zA-Zа-яА-ЯёЁäöüÄÖÜßáéúőóüöíÁÉÚŐÓÜÖÍ-]/i, '');
    if (latinAndCyrillic.length < val.length) {
      return {
        isValid: false,
        errorText: 'errors.latinAndCyrillic',
      };
    }

    return {
      isValid: true,
      errorText: '',
    };
  }

  public static validateNumber(val: string): IValidationResult {
    if (isNaN(+val) || val == null || this.isEmpty(val)) {
      return {
        isValid: false,
        errorText: 'errors.notNumber',
      };
    }

    return {
      isValid: true,
      errorText: '',
    };
  }

  public static validateText(val: string): IValidationResult {
    if (val != null) {
      val.split('').forEach((element) => {
        if (!isNaN(+element) || element == null || this.isEmpty(element)) {
          return (val = 'error');
        }
      });
      if (val == 'error')
        return {
          isValid: false,
          errorText: 'errors.onlyText',
        };
    }
    return {
      isValid: true,
      errorText: '',
    };
  }

  public static isEmpty(str) {
    return !str || str.length === 0;
  }

  /**
   * Проверка что электронная почта кашерная.
   * В случае некашерности возвращает IValidationResult с текстом ошибки.
   */
  public static validateEmail(val: string): IValidationResult {
    if (RegexUtils.isNullOrWhitespace(val)) {
      return {
        isValid: false,
        errorText: ERROR_REQUIRED,
      };
    }

    if (!RegexUtils.isValidEmail(val)) {
      return {
        isValid: false,
        errorText: 'errors.invalid',
      };
    }

    return {
      isValid: true,
      errorText: '',
    };
  }

  /**
   * Проверка что номер телефона кашерный.
   * В случае некашерности возвращает IValidationResult с текстом ошибки.
   */
  public static validatePhone(val: string): IValidationResult {
    // закомментил, потому что, если нужно проверить, что телефон введен,
    // можно использовать Validators.required при создании формы

    // if (RegexUtils.isNullOrWhitespace(val)) {
    //     return {
    //         isValid: false,
    //         errorText: ERROR_REQUIRED
    //     };
    // }

    if (!RegexUtils.isValidPhone(val.replace('+', ''))) {
      return {
        isValid: false,
        errorText: 'errors.invalid',
      };
    }

    if (val.replace('+', '').length < 6) {
      return {
        isValid: false,
        errorText: 'errors.min6',
      };
    }

    if (val.replace('+', '').length > 11) {
      return {
        isValid: false,
        errorText: 'errors.max11',
      };
    }

    return {
      isValid: true,
      errorText: '',
    };
  }

  public static validateNotEmpty(val: string): IValidationResult {
    if (RegexUtils.isNullOrWhitespace(val)) {
      return {
        isValid: false,
        errorText: ERROR_REQUIRED,
      };
    }

    return {
      isValid: true,
      errorText: '',
    };
  }

  /** валидация пароля */
  public static validatePassword(password: string): IValidationResult {
    if (RegexUtils.isNullOrWhitespace(password)) {
      return {
        isValid: false,
        errorText: ERROR_REQUIRED,
      };
    }

    if (!/[A-ZА-ЯЁ]/.test(password)) {
      return {
        isValid: false,
        errorText: 'errors.password.atLeastOneUpperCase',
      };
    }

    if (!/[a-zа-яё]/.test(password)) {
      return {
        isValid: false,
        errorText: 'errors.password.atLeastOneLowerCase',
      };
    }

    if (/[А-ЯЁ]/.test(password) || /[а-яё]/.test(password)) {
      return {
        isValid: false,
        errorText: 'errors.password.noCyrillic',
      };
    }

    if (!/\d/.test(password)) {
      return {
        isValid: false,
        errorText: 'errors.password.atLeastOneDigit',
      };
    }

    if (password.length < 6) {
      return {
        isValid: false,
        errorText: 'errors.password.minLength',
      };
    }

    if (password.length > 100) {
      return {
        isValid: false,
        errorText: 'errors.password.maxLength',
      };
    }

    return {
      isValid: true,
      errorText: '',
    };
  }

  /** валидация подтверждения пароля */
  public static validatePasswordConfirmation(
    password: string,
    confirmation: string,
  ): IValidationResult {
    if (RegexUtils.isNullOrWhitespace(confirmation)) {
      return {
        isValid: false,
        errorText: ERROR_REQUIRED,
      };
    }

    if (password !== confirmation) {
      return {
        isValid: false,
        errorText: 'errors.passwordConfirmation.notMatch',
      };
    }

    return {
      isValid: true,
      errorText: '',
    };
  }

  /** валидация старого пароля */
  public static validatePasswordOld(password: string): IValidationResult {
    if (RegexUtils.isNullOrWhitespace(password)) {
      return {
        isValid: false,
        errorText: 'errors.oldPassword.FillOld',
      };
    }

    return {
      isValid: true,
      errorText: '',
    };
  }

  /**
   * Валидация введенного кода подтверждения
   */
  public static validateConfirmationCodeOnlyRequired(code: string): IValidationResult {
    if (RegexUtils.isNullOrWhitespace(code)) {
      return {
        isValid: false,
        errorText: ERROR_REQUIRED,
      };
    }

    return {
      isValid: true,
      errorText: '',
    };
  }

  /**
   * Валидация введенного кода подтверждения
   */
  public static validateConfirmationCode(code: string): IValidationResult {
    if (RegexUtils.isNullOrWhitespace(code)) {
      return {
        isValid: false,
        errorText: ERROR_REQUIRED,
      };
    }

    if (!RegexUtils.isValidConfirmationCode(code)) {
      return {
        isValid: false,
        errorText: 'errors.confirmationCode.invalid',
      };
    }

    return {
      isValid: true,
      errorText: '',
    };
  }

  public static validateEndDate(startDate: Date, endDate: Date): IValidationResult {
    if (startDate > endDate) {
      return {
        isValid: false,
        errorText: 'errors.invalidEndDate',
      };
    }

    return {
      isValid: true,
      errorText: '',
    };
  }

  /** валидация даты. Дата старта не была больше даты окончания периода*/
  public static validateDate(date: any): IValidationResult {
    var today = new Date();
    if (date > today || date == 'Invalid Date') {
      return {
        isValid: false,
        errorText: 'Invalid date',
      };
    }
    return {
      isValid: true,
      errorText: '',
    };
  }

  /** валидация даты*/
  public static validateTodayDate(date: any): IValidationResult {
    var today = new Date().setHours(0, 0, 0, 0);
    if (date < today || date == 'Invalid Date') {
      return {
        isValid: false,
        errorText: 'Invalid date',
      };
    }
    return {
      isValid: true,
      errorText: '',
    };
  }
}

/**
 * Результат валидации
 */
export interface IValidationResult {
  /**
   * Валидно/невалидно
   */
  isValid: boolean;
  /**
   * Текст сообщения об ошибке
   */
  errorText: string;
}
