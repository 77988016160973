import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared/component/base/base.component';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService, EventService } from '../shared/services';
import { authSubpages } from './auth.subpages';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent extends BaseComponent implements OnInit {
  currentLang: string;
  subpagesMenu = authSubpages;
  currentActiveTab: any;
  currentYear: any;
  environment = environment;

  constructor(
    public authService: AuthService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private translate: TranslateService,
    private eventService: EventService,
    private bsLocaleService: BsLocaleService,
  ) {
    super(translate, modalService);
    var t = this;
    t.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    t.currentYear = new Date().getFullYear();
    // Изменение локализации
    t.translate.setDefaultLang('en');
    t.currentLang = localStorage.getItem('localization');
    if (!t.currentLang) t.currentLang = 'en';
    t.translate.use(t.currentLang);
    localStorage.setItem('localization', t.currentLang);
  }

  ngOnInit(): void {
    var t = this;
    var subpageRoute = t.activateRoute.snapshot.paramMap.get('subpageRoute');
    t.currentActiveTab = t.subpagesMenu.find((tab) => tab.route == subpageRoute);
    if (!subpageRoute || !t.currentActiveTab) {
      t.navigateTab(t.subpagesMenu[0].route);
    }
    t.activateTab(t.currentActiveTab);
  }

  switchLanguage() {
    let t = this;

    let langToSwitch = t.currentLang == 'ru' ? 'en' : 'ru';
    t.currentLang = langToSwitch;

    localStorage.setItem('localization', langToSwitch);
    t.translate.use(langToSwitch);
    t.bsLocaleService.use(langToSwitch);

    t.eventService.LangChangeEvent.emit(langToSwitch);
  }

  ngOnDestroy() {
    this.currentActiveTab.isActive = false;
  }

  public navigateTab(route: string) {
    var t = this;
    t.router.navigateByUrl('/auth/' + route);
  }

  public activateTab(item) {
    var t = this;
    var currentTab = t.subpagesMenu.find((val) => val.title == item.title);
    currentTab.isActive = true;
    t.currentActiveTab = currentTab;
  }
}
