import { Injectable } from '@angular/core';
import { IResponse } from '../interfaces';
import { ApiService } from './api.service';
import { PasswordChangePostRequest } from '../model';

const accountApiUrl = 'api/Account/';

export interface IRestorePass {
  email: string;
  localization: string;
}

export interface IRegister {
  contact: string;
  password: string;
  confirmPassword: string;
  captcha: string;
  refUser: string;
  localization: string;
  lastName: string;
  firstName: string;
  tgUsername?: string;
  telegramId?: string;
  phone: string;
}

@Injectable({
  providedIn: 'root',
})
export class PublicService {
  constructor(private api: ApiService) {}

  //регистрация
  public register(registerModel: IRegister): Promise<IResponse<any>> {
    return this.api.postAnonym(accountApiUrl + 'Register', registerModel).toPromise();
  }

  //восстановление пароля
  public restorePass(restore: IRestorePass): Promise<IResponse<any>> {
    return this.api.postAnonym(accountApiUrl + 'ResetPasswordRequest', restore).toPromise();
  }

  //смена пароля
  public changePassword(model: PasswordChangePostRequest): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(accountApiUrl + '/ChangePassword', model).toPromise();
  }
}
