import { Injectable } from '@angular/core';
import { IndexType } from '../enums';
import { IResponse } from '../interfaces';
import { IndexModel, DividendsPredictionModel, CustodyBalanceModel } from '../model';
import { ApiService } from './api.service';
import { ComboChartDataModel } from '../model/comboChartData.model';

const apiUrl = 'api/index/';

@Injectable({
  providedIn: 'root',
})
export class IndexService {
  constructor(private api: ApiService) {}

  public getIndexInfo(type: IndexType): Promise<IResponse<IndexModel>> {
    return this.api.get<IndexModel>(apiUrl + 'getIndexInfo/' + type).toPromise();
  }

  public getUserAvailableDividends(type: IndexType): Promise<IResponse<number>> {
    return this.api.get<number>(apiUrl + 'getUserAvailableDividends/' + type).toPromise();
  }

  public getPredictedDividendsSum(type: IndexType): Promise<IResponse<DividendsPredictionModel>> {
    return this.api
      .get<DividendsPredictionModel>(apiUrl + 'getPredictedDividendsSum/' + type)
      .toPromise();
  }

  public getUserDividendsHistoryForChart(type: IndexType): Promise<IResponse<ComboChartDataModel>> {
    return this.api
      .get<ComboChartDataModel>(apiUrl + 'getUserDividendsHistoryForChart/' + type)
      .toPromise();
  }

  public createtLastDividendsReportForUser(): Promise<IResponse<string>> {
    return this.api.get<string>(apiUrl + 'createLastDividendsReportForUser').toPromise();
  }

  public getCustodyBalances(): Promise<IResponse<CustodyBalanceModel[]>> {
    return this.api.get<CustodyBalanceModel[]>(apiUrl + 'getAllCustodyBalances').toPromise();
  }
}
