import { TransactionType } from '../enums/transactionType';

export var transactionType = [
  { value: TransactionType.All, label: 'All' },
  { value: TransactionType.Receive, label: 'Deposit' },
  { value: TransactionType.Send, label: 'Withdraw' },
  { value: TransactionType.Buy, label: 'Purchase' },
  { value: TransactionType.Sell, label: 'Sell' },
  { value: TransactionType.WithdrawTokenDividends, label: 'Dividends withdrawal' },
  { value: TransactionType.AirInsurancePayment, label: 'Air insurance payment' },
  { value: TransactionType.Stake, label: 'Stake' },
];
