import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../../services/event.service';
import { NavService } from '../../services/nav.service';
import { AuthService, SubscriptionService, UserService } from '../../services';
import { CryptoUserService } from '../../services/cryptoUser.service';
import { CryptoUserModel, HeaderNotificationModel, NotificationModel } from '../../model';
import { BaseComponent } from '../base/base.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit {
  currentLang: string;
  logOutIcon: string = '../../../../assets/icons/logout_header.png';
  cryptoUser: () => CryptoUserModel;
  headerNotification: HeaderNotificationModel = new HeaderNotificationModel();
  public notifButtonText: string = 'Mark all as read';
  user: CryptoUserModel = new CryptoUserModel();
  avatarUrl: string;

  constructor(
    public navService: NavService,
    private translate: TranslateService,
    private eventService: EventService,
    public authService: AuthService,
    public cryptoUserService: CryptoUserService,
    private subscriptionService: SubscriptionService,
    private modalService: NgbModal,
    public router: Router,
    private userService: UserService,
    private bsLocaleService: BsLocaleService,
  ) {
    super(translate, modalService);
    var t = this;
    t.cryptoUser = () => {
      if (!!t.cryptoUserService.get()) return t.cryptoUserService.get();
      else return new CryptoUserModel();
    };
    t.headerNotification.notCheckedCount = 0;
    t.headerNotification.notifications = [];

    // Изменение локализации
    t.translate.setDefaultLang('en');
    t.currentLang = localStorage.getItem('localization');
    if (!t.currentLang) t.currentLang = 'en';
    t.translate.use(t.currentLang);
    localStorage.setItem('localization', t.currentLang);
  }

  ngOnInit(): void {
    let t = this;
    t.setLoading(true);
    t.getNotifications(this.currentLang);
    Promise.all([t.refreshCryptoUser()]).finally(() => {
      t.setLoading(false);
    });
  }

  private refreshCryptoUser() {
    var t = this;
    return t.cryptoUserService.refresh().then((resp) => {
      t.user = Object.assign(Object.create(Object.getPrototypeOf(resp)), resp);
      t.avatarUrl = t.user.avatarUrl ?? t.defaultAvatarUrl;
    });
  }

  getNotifications(lang) {
    var t = this;
    t.subscriptionService.getLocalizedNotifications(lang).then((resp) => {
      t.headerNotification = resp.data;
    });
  }

  checkNotification(notif: NotificationModel) {
    if (notif.isChecked) return;
    notif.isChecked = true;
    var t = this;
    t.subscriptionService.checkNotification(notif.id).then((resp) => {
      if (resp.data) {
        t.headerNotification.notCheckedCount--;
      }
    });
  }

  checkAllNotifications() {
    var t = this;
    t.setLoading(true);
    t.subscriptionService
      .checkAllNotifications()
      .then((resp) => {
        if (resp.data) {
          t.headerNotification.notCheckedCount = 0;
          t.headerNotification.notifications.map((x) => (x.isChecked = true));
          t.notifButtonText = 'All notifications checked';
          t.eventService.NotifCountEvent.emit(t.headerNotification.notCheckedCount);
          t.showSuccess('All notifications checked');
        }
      })
      .catch((er) => t.showResponseError(er))
      .finally(() => t.setLoading(false));
  }

  openLearningHub() {
    this.router.navigateByUrl('/bookstore/products');
  }

  switchLanguage() {
    let t = this;

    let langToSwitch = t.currentLang == 'ru' ? 'en' : 'ru';
    t.currentLang = langToSwitch;

    localStorage.setItem('localization', langToSwitch);
    t.translate.use(langToSwitch);
    t.bsLocaleService.use(langToSwitch);

    t.getNotifications(langToSwitch);
    t.eventService.LangChangeEvent.emit(langToSwitch);
  }

  toSettings() {
    this.router.navigate(['user-settings']);
  }

  collapseSidebar() {
    this.navService.collapseSidebar = !this.navService.collapseSidebar;
    this.navService.showNotif = false;
  }
}
