import { Routes } from '@angular/router';
export const content: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('../../components/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'wallet/:subpageRoute',
    loadChildren: () => import('../../components/wallet/wallet.module').then((m) => m.WalletModule),
  },
  {
    path: 'wallet',
    loadChildren: () => import('../../components/wallet/wallet.module').then((m) => m.WalletModule),
  },
  {
    path: 'user-profile/:subpage',
    loadChildren: () =>
      import('../../components/user-profile/user-profile.module').then((m) => m.UserProfileModule),
  },
  {
    path: 'auth/:subpageRoute',
    loadChildren: () => import('../../app.module').then((m) => m.AppModule),
  },
  {
    path: 'my-assets/:asset',
    loadChildren: () =>
      import('../../components/my-assets/my-assets.module').then((m) => m.MyAssetsModule),
  },
  {
    path: 'user-settings',
    loadChildren: () =>
      import('../../components/user-profile/user-profile.module').then((m) => m.UserProfileModule),
  },
  {
    path: 'ref-payouts',
    loadChildren: () =>
      import('../../components/ref-payouts/ref-payouts.module').then((m) => m.RefPayoutsModule),
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('../../components/notifications/notifications.module').then(
        (m) => m.NotificationsModule,
      ),
  },
  {
    path: 'comfort-travel/:subpage',
    loadChildren: () =>
      import('../../components/comfort-travel/comfort-travel.module').then(
        (m) => m.ComfortTravelModule,
      ),
  },
  {
    path: 'stake',
    loadChildren: () => import('../../components/stake/stake.module').then((m) => m.StakeModule),
  },
  {
    path: 'learning/:subpage',
    loadChildren: () =>
      import('../../components/learning/learning.module').then((m) => m.LearningModule),
  },
  {
    path: 'cart',
    loadChildren: () =>
      import('../../components/learning-hub/learning-hub-cart/cart.module').then(
        (m) => m.CartModule,
      ),
  },
  {
    path: 'bookstore/:subpage',
    loadChildren: () =>
      import('../../components/learning-hub/learning-hub.module').then((m) => m.LearningHubModule),
  },
];

export const adminContent: Routes = [
  { path: '', redirectTo: 'admin/users', pathMatch: 'full' },
  {
    path: 'auth/:subpageRoute',
    loadChildren: () => import('../../app.module').then((m) => m.AppModule),
  },
  {
    path: 'admin/analytics',
    loadChildren: () =>
      import('../../components/admin/analytics/admin-analytics.module').then(
        (m) => m.AdminAnalyticsModule,
      ),
  },
  {
    path: 'admin/users',
    loadChildren: () =>
      import('../../components/admin/users/admin-users.module').then((m) => m.AdminUsersModule),
  },
  {
    path: 'admin/user-profile/:id',
    loadChildren: () =>
      import('../../components/admin/user-profile/admin-user-profile.module').then(
        (m) => m.AdminUserProfileModule,
      ),
  },
  {
    path: 'admin/documentation',
    loadChildren: () =>
      import('../../components/admin/documentation/documentation.module').then(
        (m) => m.DocumentationModule,
      ),
  },
  {
    path: 'admin/dashboard',
    loadChildren: () =>
      import('../../components/admin/dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'admin/settings',
    loadChildren: () =>
      import('../../components/admin/settings/settings.module').then((m) => m.SettingsModule),
  },
];
