import { Injectable } from '@angular/core';
import { IResponse } from '../interfaces';
import { ApiService } from './api.service';
import { SubscriptionModel } from '../model';

const telegramApiUrl = 'api/subscription/';

@Injectable({
  providedIn: 'root',
})
export class TelegramBotService {
  constructor(private api: ApiService) {}

  public checkSubscriptionCode(code: string): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(telegramApiUrl + 'checkcode/' + code).toPromise();
  }

  public getSubscriptioninfo(): Promise<IResponse<SubscriptionModel>> {
    return this.api.get<SubscriptionModel>(telegramApiUrl + 'getSubscriptionInfo').toPromise();
  }
}
