import { Injectable, EventEmitter, Output } from '@angular/core';
import { IResponse } from '../interfaces';
import { ApiService } from './api.service';
import { WalletHistoryTransModel } from '../model';
import { RewardsFilterModel } from '../model/RewardsFilterModel';
import { RewardsModel } from '../model/rewards.model';
import { PartnerModel } from '../model/partner.model';

const rewardsApiUrl = 'api/rewards/';

@Injectable({
  providedIn: 'root',
})
export class RewardsService {
  constructor(private api: ApiService) {}

  //инфа по транзам
  public getUserRewardsHistory(filter: RewardsFilterModel): Promise<IResponse<RewardsModel[]>> {
    return this.api.post<RewardsModel[]>(rewardsApiUrl + 'getuserrewards', filter).toPromise();
  }

  // получить список партнеров
  public getUserPartners(): Promise<IResponse<PartnerModel[]>> {
    return this.api.get<PartnerModel[]>(rewardsApiUrl + 'getUserPartners').toPromise();
  }
}
