import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { menuitems } from '../constants/menuicons';
import { HeaderButtonModel } from '../model';
import { permissions } from '../constants';

// Menu
export interface Page {
  path?: string;
  title?: string;
  icon?: any;
  image?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Page[];
  roles?: string[];
  header?: string;
  notShowInSidebar?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NavService {
  public readonly buttonPrimary = 'btn-primary'; // чтобы линтер не кидал в ошибку
  public screenWidth: any;
  public showNotif: boolean = false;
  public collapseSidebar: boolean = true;
  public collapseHeaderInfo: boolean = false;
  menuitems = menuitems;
  permissions = permissions;

  constructor() {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = false;
      this.collapseHeaderInfo = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }

  MainPages: Page[] = [
    { path: '/dashboard', title: 'Dashboard', type: 'link', image: menuitems.dashboard },
    { path: '/wallet', title: 'Wallet', type: 'link', image: menuitems.wallet },
    {
      path: '/documents',
      title: 'Documents',
      type: 'link',
      image: menuitems.documents,
      notShowInSidebar: true,
    },
    { path: '/user-profile/:subpage', title: 'Profile', notShowInSidebar: true },
    { path: '/user-settings', title: 'Settings', notShowInSidebar: true },
    { path: '/my-assets/TTA', title: 'My assets', type: 'link', image: menuitems.assets },
    {
      path: '/ref-payouts',
      title: 'Trade rewards',
      header: 'Trade Rewards',
      type: 'link',
      image: menuitems.refPayouts,
    },
    { path: '/notifications', title: 'Notifications', notShowInSidebar: true },
    {
      path: '/comfort-travel/information',
      title: 'Comfort-travel',
      header: 'Program «Comfort-travel»',
      type: 'link',
      image: menuitems.comfortTravel,
    },
    {
      path: '/stake',
      title: 'Staking',
      header: 'Program «Staking»',
      type: 'link',
      image: menuitems.stake,
    },
    {
      path: '/learning/available',
      title: 'Learning',
      header: 'Learning',
      type: 'link',
      image: menuitems.learning,
    },
    {
      path: '/bookstore/products',
      title: 'Books',
      header: 'Books',
      type: 'link',
      image: menuitems.learningHub,
    },
  ];

  AdminPages: Page[] = [
    {
      path: '/admin/dashboard',
      title: 'Dashboard',
      type: 'link',
      image: menuitems.dashboard,
      roles: [this.permissions.Master],
    },
    {
      path: '/admin/analytics',
      title: 'Analytics',
      type: 'link',
      image: menuitems.wallet,
      roles: [this.permissions.Master],
    },
    {
      path: '/admin/users',
      title: 'Users',
      type: 'link',
      image: menuitems.users,
      roles: [this.permissions.Master],
    },
    {
      path: '/admin/user-profile/:id',
      title: 'User profile',
      notShowInSidebar: true,
      roles: [this.permissions.Master],
    },
    {
      path: '/admin/documentation',
      title: 'Documents',
      type: 'link',
      image: menuitems.documents,
      roles: [this.permissions.Master],
    },
    {
      path: '/admin/settings',
      title: 'Settings',
      type: 'link',
      image: menuitems.settings,
      roles: [this.permissions.Master],
    },
  ];

  HEADERBUTTONS: HeaderButtonModel[] = [
    {
      itemTitle: 'Materials',
      buttonTitle: 'Add new',
      buttonNumber: 0,
      className: this.buttonPrimary,
    },
    {
      funcName: 'WithdrawAll()',
      itemTitle: 'Flow distribution analytics',
      buttonTitle: 'Withdraw all',
      headerTitle: 'Flow distribution analytics',
      buttonNumber: 0,
      className: this.buttonPrimary,
      needShow: true,
    },
    {
      funcName: 'Back()',
      itemTitle: 'Profile',
      buttonTitle: 'Back',
      headerTitle: 'Profile',
      buttonNumber: 0,
      className: 'btn-outline-info',
      needShow: true,
    },
    {
      funcName: 'Back()',
      itemTitle: 'Settings',
      buttonTitle: 'Back',
      buttonNumber: 0,
      className: 'btn-outline-info',
      needShow: true,
    },
    {
      itemTitle: 'Documentation',
      buttonTitle: 'Add new',
      buttonNumber: 0,
      url: '/documentation',
      className: this.buttonPrimary,
      needShow: true,
    },
    //{ itemTitle: 'Settings', buttonTitle: 'Save', url: '/settings', buttonNumber: 0, className: 'btn-primary px-4', needShow: true }
  ];
  // Array
  mainItems = new BehaviorSubject<Page[]>(this.MainPages);
  adminItems = new BehaviorSubject<Page[]>(this.AdminPages);
}
