<div class="dark-body-only" id="outer-container">
  <!-- page-wrapper Start-->
  <div class="page-wrapper" id="canvas-bookmark">
    <ng-container *ngIf="!environment.buildAdmin">
      <app-header></app-header>
    </ng-container>
    <ng-container *ngIf="environment.buildAdmin">
      <app-admin-header></app-admin-header>
    </ng-container>
    <!-- Page Body Start-->
    <div
      class="page-body-wrapper d-flex position-relative"
      [ngClass]="{
        colapsedSidebar: !navServices.collapseSidebar,
        openSidebar: navServices.collapseSidebar
      }"
    >
      <div class="black-overlay" (click)="collapseSidebar()"></div>
      <div class="page-sidebar light-sidebar" id="pages-sidebar">
        <app-sidebar></app-sidebar>
      </div>
      <!-- <div class="myblocker" (click)="hideMenu()" [hidden] ="navServices.collapseSidebar && navServices.collapseHeaderInfo">
      </div> -->
      <div class="page-body w-100">
        <app-breadcrumb></app-breadcrumb>
        <main [@animateRoute]="getRouterOutletState(o)">
          <router-outlet #o="outlet"></router-outlet>
        </main>
      </div>
    </div>
    <!-- footer start-->
    <div class="page-body-wrapper">
      <footer class="footer">
        <app-footer></app-footer>
      </footer>
    </div>
    <!-- footer End-->
    <!-- Page Body End-->
  </div>
  <!-- page-wrapper End-->
</div>
