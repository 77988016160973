export var permissions: { [key: string]: string } = {
  Master: 'Master',
  User: 'User',
  ExchangeUser: 'ExchangeUser',
  WithdrawMaster: 'WithdrawMaster',
  EmailMaster: 'EmailMaster',
  TwoFactorMaster: 'TwoFactorMaster',
  VideoMaster: 'VideoMaster',
  SettingsMaster: 'SettingsMaster',
  AllUsersMaster: 'AllUsersMaster',
  EventMaster: 'EventMaster',
};
