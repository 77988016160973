import { UserModel } from '../model/user.model';
import { ApiService } from '.';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const userServiceApiUrl = 'api/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public user: UserModel;

  constructor(private api: ApiService) {}

  public clear() {
    this.user = undefined;
  }
  public init(): Promise<UserModel> {
    var promise: Promise<UserModel>;
    if (this.get() == undefined || this.get() == null) {
      promise = this.refresh();
    } else {
      promise = new Promise<UserModel>((resolve, reject) => {
        resolve(this.get());
      });
    }

    return promise;
  }

  public isAuthentificated(): boolean {
    var at = localStorage.getItem('accessToken');
    return at != null && at.length > 0;
  }

  public refresh = (): Promise<UserModel> => {
    return this.api
      .get<UserModel>(userServiceApiUrl)
      .toPromise()
      .then((resp) => {
        this.user = resp.data;
        if (this.isAuthentificated()) return this.user;
        else return new UserModel();
      });
  };

  public get(): UserModel {
    return this.user;
  }

  //возвращает роли текущего пользователя
  public getRole(): string[] {
    if (this.user != null) return this.user.roles;
    else return null;
  }

  //поверка на присутствие роли у пользователя
  //возвращает true, если у него есть хотя бы одна из указанных в массиве accessRoles
  public verifyRole(accessRoles: string[]): boolean {
    var t = this;
    if (!accessRoles) {
      return false;
    }
    var resp = false;
    var userRoles = t.getRole();
    if (userRoles != null) {
      accessRoles.forEach((role) => {
        if (userRoles.indexOf(role) != -1) {
          resp = true;
        }
      });
    }
    return resp;
  }
}
