import { Injectable, EventEmitter, Output } from '@angular/core';
import { IResponse } from '../interfaces';
import { ApiService } from './api.service';
import { WithdrawPostRequest } from '../model/postRequest/withdrawPostRequest';
import {
  WalletHistoryTransModel,
  WalletHistoryTransFilterModel,
  BalanceModel,
  BuyPostRequest,
  WithdrawBySectionsPostRequest,
  SellPostRequest,
  SuccessFeeRequestModel,
  WalletModel,
} from '../model';
import { StakePostRequest } from '../model/postRequest/stakePostRequest';
import { TotalStakedModel } from '../model/totalStakedModel';
import { WalletType } from '../enums/walletType';
import { CurrencyType, Chain } from '../enums';
import { TonBuyRequestModel } from '../model/tonBuyRequest.model';

const walletApiUrl = 'api/wallet/';

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  @Output() public BalanceUpdateEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() WithdrawEvent = new EventEmitter<boolean>();
  setWithdrawCompleted() {
    this.WithdrawEvent.emit();
  }

  constructor(private api: ApiService) {}

  // Map: WalletType, (Map: CryptoType(Str), BalanceModel)
  private walletsBalances: Map<number, Map<string, BalanceModel>>;

  //получение адреса системного кошелька для пополнения USDT
  public getInfo(): Promise<IResponse<string>> {
    return this.api.get<string>(walletApiUrl + 'info').toPromise();
  }

  public stake(stakeRequest: StakePostRequest): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(walletApiUrl + 'stake', stakeRequest).toPromise();
  }

  public getTotalStaked(currencyType: CurrencyType): Promise<IResponse<TotalStakedModel>> {
    return this.api.get<TotalStakedModel>(walletApiUrl + 'totalStaked/' + currencyType).toPromise();
  }

  public getUserStakedBalance(currencyType: CurrencyType): Promise<IResponse<number>> {
    return this.api.get<number>(walletApiUrl + 'getUserStakedBalance/' + currencyType).toPromise();
  }

  //инфа по транзам
  public getUserWalletHistoryTranz(
    filter: WalletHistoryTransFilterModel,
  ): Promise<IResponse<WalletHistoryTransModel[]>> {
    return this.api
      .post<WalletHistoryTransModel[]>(walletApiUrl + 'getusertranz', filter)
      .toPromise();
  }

  /** Инициализировать сервис */
  public initBalance(): Promise<Map<number, Map<string, BalanceModel>>> {
    if (!this.getAllBalances()) {
      return this.refreshBalance();
    } else {
      return new Promise<Map<number, Map<string, BalanceModel>>>((resolve) => {
        resolve(this.walletsBalances);
      });
    }
  }

  // public clear(){
  //   this.walletsBalances = undefined;
  //   this.systemWallets= undefined;
  //   this.walletsData = undefined;
  // }

  // /**
  //  * Получение массива всех данных о балансах системный кошелёк вместе с внешним
  //  * @returns
  //  */
  // public getWalletsData(){
  //   return this.walletsData;
  // }

  /**
   * просто получение мыссива кошельков где первый ключ это тип а второй ключ это название валюты
   * @returns
   */
  public getAllBalances(): Map<number, Map<string, BalanceModel>> {
    return this.walletsBalances;
  }

  /**
   * @param walletType тип кошелька
   * @param type название валюты (пока стринг)
   */
  public getBalance(walletType: number, type: string): number {
    return this.walletsBalances?.get(walletType)?.get(type)?.balance ?? 0;
  }

  // /**
  //  * @param type название валюты (пока стринг)
  //  */
  public getSystemBalance(type: string): number {
    return this.getBalance(WalletType.System, type);
  }

  public getFullBalance(type: string): number {
    var t = this;
    if (!t.walletsBalances) return 0;
    var systemBalance = t.walletsBalances.get(WalletType.System)?.get(type)?.balance ?? 0;
    var externalBalance = t.walletsBalances.get(WalletType.External)?.get(type)?.balance ?? 0;
    return systemBalance + externalBalance;
  }

  // /**
  //  * обновление системного кошелька
  //  * @returns
  //  */
  public refreshBalance = (): Promise<Map<number, Map<string, BalanceModel>>> => {
    var t = this;
    return new Promise<Map<number, Map<string, BalanceModel>>>((resolve, reject) => {
      this.api
        .get<BalanceModel[]>(walletApiUrl + 'getAllUserBalances')
        .toPromise()
        .then((resp) => {
          t.walletsBalances = new Map<number, Map<string, BalanceModel>>();
          for (var wallet of resp.data) {
            if (!t.walletsBalances.has(wallet.walletType))
              t.walletsBalances.set(wallet.walletType, new Map<string, BalanceModel>());
            t.walletsBalances.get(wallet.walletType).set(wallet.type, wallet);
          }
          t.BalanceUpdateEvent.emit();
          resolve(t.walletsBalances);
        })
        .catch((er) => {
          reject(er);
        });
    });
  };

  public buy(buy: BuyPostRequest): Promise<IResponse<TonBuyRequestModel>> {
    return this.api.post<TonBuyRequestModel>(walletApiUrl + 'buy', buy).toPromise();
  }

  public requestWithdraw(withdraw: WithdrawPostRequest): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(walletApiUrl + 'withdraw', withdraw).toPromise();
  }

  public sellToken(model: SellPostRequest): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(walletApiUrl + 'sell', model).toPromise();
  }

  /**
   * получить балансы определенного юзера
   */
  public getBalanceById(userId: number) {
    var t = this;
    return t.api.get<BalanceModel[]>(walletApiUrl + 'getbalancebyid/' + userId).toPromise();
    // .then((resp) => {
    //   let walletsBalances: any[] = [];
    //   let systemWallets: any[] = [];
    //   walletsBalances[0] = [];
    //   walletsBalances[1] = [];
    //   resp.data.forEach(wal => {
    //     walletsBalances[wal.walletType][wal.type] = wal;
    //         systemWallets.push(wal);
    //   });
    //   t.walletsBalances = walletsBalances;
    //   t.systemWallets = systemWallets;
    //   t.walletsData = resp.data;
    //   return resp.data;
    // });
  }

  public getGrowthPercent(): Promise<IResponse<number>> {
    return this.api.get<number>(walletApiUrl + 'getgrowthpercent').toPromise();
  }

  public createWithdrawAnalytics(
    filter: WithdrawBySectionsPostRequest,
  ): Promise<IResponse<boolean>> {
    return this.api.post<boolean>(walletApiUrl + 'createWithdrawAnalytics', filter).toPromise();
  }

  public createWithdrawDividends(
    contractWithdraw: WithdrawPostRequest,
  ): Promise<IResponse<boolean>> {
    return this.api
      .post<boolean>(walletApiUrl + 'createWithdrawDividends', contractWithdraw)
      .toPromise();
  }

  public precalculateSuccessFee(sFeeRequest: SuccessFeeRequestModel): Promise<IResponse<number>> {
    return this.api.post<number>(walletApiUrl + 'precalculateSuccessFee', sFeeRequest).toPromise();
  }

  public getPersonalWalletBalance(type: CurrencyType): Promise<IResponse<number>> {
    return this.api.get<number>(walletApiUrl + 'getPersonalWalletBalance/' + type).toPromise();
  }

  /**
   * Получение данных о системных кошельках (адрес, чейн)
   */
  public getUserSystemWallets(): Promise<IResponse<Map<Chain, WalletModel>>> {
    return this.api.get<Map<Chain, WalletModel>>(walletApiUrl + 'getSystemWallets').toPromise();
  }
}
