import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminLoginComponent } from './admin-login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AdminLoginComponent],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, SharedModule, TranslateModule],
  exports: [AdminLoginComponent],
})
export class AdminLoginModule {}
