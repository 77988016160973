import { BuyProductType } from '../enums/buyProductType';

export enum Commission {
  Withdraw = 1,
  DividendWithdraw,
  SellToken = 8,
  DeploySmartWallet = 11,
}

export class GasLimit {
  public static commissionDict: { [id: number]: number } = {
    [Commission.Withdraw]: 80000,
    [Commission.DividendWithdraw]: 140000,
    [Commission.SellToken]: 140000,
    [Commission.DeploySmartWallet]: 175000,
  };

  /** Газ на покупку продуктов за ETH */
  public static purchaseGasByProductType: { [id: number]: number } = {
    [BuyProductType.TTA]: 190000,
  };

  /** Газ на покупку продуктов за ERC-20 токены */
  public static purchaseGasByProductTypeErc20: { [id: number]: number } = {
    [BuyProductType.TTA]: 220000,
  };

  public static defaultPurchaseGas = this.commissionDict[Commission.Withdraw];
}
