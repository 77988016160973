import { Chain } from 'src/app/shared/enums';

export const environment = {
  envName: 'prod',
  production: true,
  apiUrl: 'https://api.triangels.vip/',
  frontUrl: 'https://my.triangels.vip/',
  tokenUrl: 'token',
  botName: 'TriAngelsBot',
  botId: '6467214240',
  buildAdmin: false,
};

export const blockexplorers: Map<Chain, string> = new Map<Chain, string>([
  [Chain.Ethereum, 'https://etherscan.io/'],
  [Chain.Arbitrum, 'https://arbiscan.io/'],
]);

export const contractAddresses = {
  TTA: '0x9b39897e99cacdbb7c6d7dcfa84938aa309c9dad',
  iTTA: '0xA961Ac2708910b542fBED63c795162C74c6099f7',
};

export const WalletConnect = {
  ProjectId: '7a9cf6086ef4eca6796ac8fe12fdc708',
};
