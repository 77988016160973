import { Component, NgZone } from '@angular/core';
import { BaseComponent } from 'src/app/shared/component/base/base.component';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService, CryptoUserService } from '../../shared/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { TwoFactorType } from 'src/app/shared/enums/twoFactorType';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseComponent {
  // @ViewChildren('telegramAuth') telegramAuth: any;

  public loginForm: FormGroup;
  public needConfirm: boolean = false;
  public showPass: boolean = false;
  public passType: string = 'password';
  public typeTwoFactorAuth: number = 0;
  public errorText: string = '';
  public botName: string = environment.botName;
  public envName: string = environment.envName;

  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private ngZone: NgZone,
    private translate: TranslateService,
    private cryptoUserService: CryptoUserService,
  ) {
    super(translate, modalService);

    this.loginForm = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  get email() {
    return this.loginForm.get('email');
  }
  get password() {
    return this.loginForm.get('password');
  }

  // Simple Login
  login() {
    let t = this;
    if (t.loginForm.invalid) {
      t.markFormGroupTouchedAndDirty(t.loginForm);
      return;
    }

    t.setLoading(true);
    var loginCred = {
      email: t.email.value,
      password: t.password.value,
    };

    t.authService
      .SignIn(loginCred)
      .then(() => {
        t.router.navigateByUrl('/dashboard');
        // t.checkNeedAgreement();
      })
      .catch((e) => {
        if (!!e.error.error) {
          t.errorText = e.error.error_description;

          let twoFactorErrors = [`failed_authorization_Email`, `failed_authorization_Google`];
          if (twoFactorErrors.includes(e.error.error)) {
            let twoFactorTypeStr = e.error.error.replace('failed_authorization_', '');
            switch (twoFactorTypeStr) {
              case 'Email':
                t.typeTwoFactorAuth = TwoFactorType.Email;
                break;
              case 'Google':
                t.typeTwoFactorAuth = TwoFactorType.GoogleAuth;
                break;
            }
            t.cryptoUserService.CredentialSetted(loginCred, t.typeTwoFactorAuth);
            t.router.navigateByUrl('/auth/confirmCode');
          }
        } else {
          t.errorText = 'System error. Please contact technical support';
        }

        t.setLoading(false);
      });
  }

  public hideOrShowPassword() {
    if (this.passType == 'password') {
      this.passType = 'text';
      this.showPass = true;
    } else {
      this.passType = 'password';
      this.showPass = false;
    }
  }

  public navigateTab(route: string) {
    var t = this;
    t.router.navigateByUrl('/auth/' + route);
  }

  // async checkNeedAgreement(){
  //   let t = this;
  //   t.agreementService.checkHasAgreed()
  //   .then(resp => {
  //     if(resp.data === false){
  //       t.showAgreementModal()
  //       .then(result => {
  //         if(result){
  //           t.agreementService.createNewAgreement(true);
  //         }
  //       })
  //     }
  //   })
  // }

  // createTelegramRegScript() {
  //   if (document.getElementById("telegram-reg-script") != null) {
  //     return;
  //   }

  //   const tgScript = document.createElement('script');
  //   tgScript.src = "https://telegram.org/js/telegram-widget.js";
  //   tgScript.async = true;

  //   this.telegramAuth.first.nativeElement.appendChild(tgScript);
  // }

  // loginTelegram() {
  //   (<any>window).Telegram.Login.auth(
  //     {
  //       bot_id: environment.botId,
  //       request_access: true
  //     },
  //     (data) => {
  //       if (!data) {
  //         return;
  //       }
  //       this.onTelegramAuth(data);
  //     }
  //   );
  // }

  // onTelegramAuth(authData: Object): void {
  //   var t = this;

  //   authData["first_name"] = authData["first_name"] != null ?
  //     encodeURIComponent(authData["first_name"]) : authData["first_name"];

  //   authData["last_name"] = authData["last_name"] != null ?
  //     encodeURIComponent(authData["last_name"]) : authData["last_name"];

  //   var authDataStr = JSON.stringify(authData);
  //   localStorage.setItem("telegramAuthData", authDataStr);

  //   var emptyCred = {
  //     email: "",
  //     password: "",
  //     confirmCode: ""
  //   };

  //   t.setLoading(true);
  //   t.authService.SignIn(emptyCred)
  //     .then((resp) => {
  //       t.setLoading(false);
  //       t.ngZone.run(() => t.router.navigateByUrl('/dashboard'));
  //       t.checkNeedAgreement();
  //       localStorage.removeItem("telegramAuthData");
  //     })
  //     .catch((e => {
  //       if (e != null && e.error != null && e.error.error == "invalid_grant") {
  //         t.ngZone.run(() => t.showError(t.translate.instant(e.error.error_description)));

  //       } else if (!!e.error && !!e.error.error && e.error.error.indexOf("failed_authorization_") != -1) {
  //         if(e.error.error.indexOf("Email") != -1) {
  //           t.typeTwoFactorAuth = -1;
  //         }

  //         if (e.error.error.indexOf("Google") != -1) {
  //           t.typeTwoFactorAuth = 2;
  //         }

  //         t.cryptoUserService.CredentialSetted(emptyCred, t.typeTwoFactorAuth);
  //         t.ngZone.run(() => t.router.navigateByUrl('/auth/confirmCode'));

  //       } else t.ngZone.run(() => t.showResponseError(e));

  //       t.ngZone.run(() => t.setLoading(false));
  //       return;
  //     }));
  // };
}
