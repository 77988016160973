import { FrontChartInterval } from '../enums';

export var FrontChartIntervalList = [
  { value: FrontChartInterval.day, label: 'day' },
  { value: FrontChartInterval.week, label: 'week' },
  { value: FrontChartInterval.month, label: 'month' },
  { value: FrontChartInterval.six_month, label: 'six_month' },
  { value: FrontChartInterval.year, label: 'year' },
  { value: FrontChartInterval.alltime, label: 'alltime' },
];
