import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentLayoutComponent } from './shared/component/content-layout/content-layout.component';
import { adminContent, content } from './shared/routes/routes';
import { AuthComponent } from './auth/auth.component';
import { AdminGuard, SecureInnerPagesGuard } from './shared/guard';
import { environment } from 'src/environments/environment';

const mainRoutes: Routes = [
  {
    path: 'auth/:subpageRoute',
    component: AuthComponent,
  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [SecureInnerPagesGuard],
    children: content,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

const adminRoutes: Routes = [
  {
    path: 'auth/:subpageRoute',
    component: AuthComponent,
  },
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [SecureInnerPagesGuard],
    children: adminContent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

var routes = environment.buildAdmin ? adminRoutes : mainRoutes;

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
