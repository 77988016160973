import { Injectable } from '@angular/core';
import { IResponse } from '../interfaces';
import {
  AllCryptoUserAndCountModel,
  AllCryptoUserModel,
  CryptoUserFilterModel,
  CryptoUserModel,
  WithdrawInfoModel,
} from '../model';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { CodeType } from '../enums';

const cryptoUserApiUrl = 'api/cryptoUser';

@Injectable({
  providedIn: 'root',
})
export class CryptoUserService {
  public cryptoUser: CryptoUserModel;
  public type2FA: number = 0;
  public email: string = '';
  credentials$: any;

  constructor(private api: ApiService) {}

  CredentialSetted(
    credentials: { email: string; password: string },
    type2FA: number,
    email?: string,
  ) {
    var t = this;
    var credentialsSubject = new BehaviorSubject<{ email: string; password: string }>(credentials);
    t.credentials$ = credentialsSubject.asObservable();
    t.type2FA = type2FA;
    t.email = credentials.email;
  }

  CleanCredentials() {
    var t = this;
    t.credentials$ = {};
    t.type2FA = 0;
  }

  public clear() {
    this.cryptoUser = undefined;
  }

  public init(): Promise<CryptoUserModel> {
    var promise: Promise<CryptoUserModel>;
    if (this.get() == undefined || this.get() == null) {
      promise = this.refresh();
    } else {
      promise = new Promise<CryptoUserModel>((resolve, reject) => {
        resolve(this.get());
      });
    }

    return promise;
  }

  public refresh = (): Promise<CryptoUserModel> => {
    var t = this;
    return this.api
      .get<CryptoUserModel>(cryptoUserApiUrl)
      .toPromise()
      .then((resp) => {
        t.cryptoUser = resp.data;
        return t.cryptoUser;
      });
  };

  public get(): CryptoUserModel {
    if ((<any>window).Chatra) {
      var me = this;
      if (this.cryptoUser) {
        (<any>window).Chatra('setIntegrationData', {
          'Id ': me.cryptoUser.id,
          Емейл: me.cryptoUser.email,
          Site: 'v2',
          Version: '261021',
          typeAuthorization: me.cryptoUser.typeAuthorization,
          twoFactorEnabled: me.cryptoUser.twoFactorEnabled,
        });
      }
    }

    return this.cryptoUser;
  }

  public getUserForAdminById(id: number): Promise<IResponse<CryptoUserModel>> {
    return this.api.get<CryptoUserModel>(cryptoUserApiUrl + '/getUserForAdmin/' + id).toPromise();
  }

  public delUser(id: number): Promise<IResponse<AllCryptoUserModel>> {
    return this.api.get<AllCryptoUserModel>(cryptoUserApiUrl + '/deluser/' + id).toPromise();
  }

  public UndelUser(id: number): Promise<IResponse<AllCryptoUserModel>> {
    return this.api.get<AllCryptoUserModel>(cryptoUserApiUrl + '/undeluser/' + id).toPromise();
  }

  public changeStatus(
    userRefStatusId: number,
    userId: number,
  ): Promise<IResponse<AllCryptoUserModel>> {
    return this.api
      .get<AllCryptoUserModel>(cryptoUserApiUrl + '/changerole/' + userRefStatusId + '/' + userId)
      .toPromise();
  }

  public getAllUsers(
    filter: CryptoUserFilterModel,
  ): Promise<IResponse<AllCryptoUserAndCountModel>> {
    return this.api
      .post<AllCryptoUserAndCountModel>(cryptoUserApiUrl + '/getallusers/', filter)
      .toPromise();
  }

  public reset2fa(id: number): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(cryptoUserApiUrl + '/resettwofactor/' + id).toPromise();
  }

  public getRefName(name: string): Promise<IResponse<string>> {
    return this.api.get<string>(cryptoUserApiUrl + '/getrefname/' + name).toPromise();
  }

  public upload(file: any): Promise<IResponse<any>> {
    return this.api.uploadAvatar<any>(cryptoUserApiUrl, file).toPromise();
  }

  public updatePersonalData(data: any): Promise<IResponse<any>> {
    return this.api.post<any[]>(cryptoUserApiUrl, data).toPromise();
  }

  public changePassword(
    oldPass: string,
    newPass: string,
    confirmPass: string,
  ): Promise<IResponse<boolean>> {
    var data = {
      oldPassword: oldPass,
      newPassword: newPass,
      newPasswordConfirm: confirmPass,
    };

    return this.api.post<boolean>(cryptoUserApiUrl + '/changepassword', data).toPromise();
  }

  public sendTwoFactor(type: CodeType = CodeType.ConfirmTwoFactor): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(cryptoUserApiUrl + '/sendTwoFactor/' + type).toPromise();
  }

  public generateNewKey(): any {
    return this.api.get<any>(cryptoUserApiUrl + '/generateauthkey').toPromise();
  }

  public confirmConnectWithKey(confirmStr: string): Promise<IResponse<boolean>> {
    return this.api
      .get<boolean>(cryptoUserApiUrl + '/confirmconnectwithkey/' + confirmStr)
      .toPromise();
  }

  public sendDeactivatedCode(): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(cryptoUserApiUrl + '/senddeactivatedcode').toPromise();
  }

  public deactivate2fa(deactive: any): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(cryptoUserApiUrl + '/deactive/' + deactive).toPromise();
  }

  public confirmCode(confirmStr: string, type: number): any {
    return this.api
      .get<boolean>(cryptoUserApiUrl + '/confirmcode/' + confirmStr + '/' + type)
      .toPromise();
  }

  public sendTwoFactorWithdraw(
    withdrawInfoModel: WithdrawInfoModel = null,
  ): Promise<IResponse<boolean>> {
    return this.api
      .post<boolean>(cryptoUserApiUrl + '/sendTwoFactor/withdraw', withdrawInfoModel)
      .toPromise();
  }

  public setPersonalAddress(address: string): Promise<IResponse<boolean>> {
    return this.api.get<boolean>(cryptoUserApiUrl + '/setPersonalAddress/' + address).toPromise();
  }
}
