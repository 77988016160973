import { Injectable } from '@angular/core';
import { IResponse } from '../interfaces';
import { BaseFilterModel, InfoAnalyticRow, InfoAnalyticFilter } from '../model';
import { ApiService } from './api.service';

import { UsersRegistryModel } from '../model'; //позже удалить

const infoAnalyticApi = 'api/infoanalitic/';

@Injectable({
  providedIn: 'root',
})
export class InfoAnalyticsService {
  constructor(private api: ApiService) {}

  public getProductInfoAnalytic(filter: InfoAnalyticFilter): Promise<IResponse<InfoAnalyticRow>> {
    return this.api
      .post<InfoAnalyticRow>(infoAnalyticApi + 'getProductInfoAnalytic', filter)
      .toPromise();
  }

  public getUsersRegistry(dateFilter: BaseFilterModel): Promise<IResponse<UsersRegistryModel[]>> {
    return this.api
      .post<UsersRegistryModel[]>(infoAnalyticApi + 'getusersregistry', dateFilter)
      .toPromise();
  }
}
