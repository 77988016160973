<div class="sidebar custom-scrollbar" id="sidebar" #sidebar>
  <div class="mt-3 mb-4 mt-sm-0 mb-sm-2">
    <img
      class="cursor-pointer"
      src="assets/images/menuitems/burger.svg"
      (click)="collapseSidebar()"
      alt=""
    />
  </div>

  <div class="desktop-show d-flex flex-column justify-content-between">
    <div [hidden]="navService.showNotif">
      <ul class="nav nav-pills d-flex flex-column" style="height: calc(100% - 42px)">
        <li
          *ngFor="let menuItem of menuItems"
          class="nav-item"
          [hidden]="needHidden(menuItem.roles)"
        >
          <a
            [routerLink]="!menuItem.type ? null : [menuItem.path]"
            class="nav-link p-0"
            (click)="toggletNavActive(menuItem)"
            *ngIf="menuItem.type === 'link' && !menuItem.notShowInSidebar"
          >
            <div
              [ngClass]="{ 'active-icon': menuItem.active === true }"
              class="d-flex align-items-center menu-item"
            >
              <div
                [ngClass]="{ 'active-image': menuItem.active === true }"
                class="title-hidden text-nowrap"
              >
                <img
                  src="{{ menuItem.image }}"
                  class="icon-color float-left mb-1"
                  height="24px"
                  width="24px"
                  alt=""
                />
                <span class="menu-title-container">
                  <span class="menu-asset green-transition">{{ menuItem.title | translate }}</span>
                </span>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div class="h-100 d-flex flex-column justify-content-between mobile-show">
    <div class="w-100 pb-4 scroll-container">
      <div (click)="toProfile()">
        <img class="avatar-circle-36 mb-3 mt-1" src="{{ getAvatar() }}" alt="" />
      </div>
      <div class="col mb-3 f-20 f-w-700 lh-120 text-uppercase" style="word-break: break-word">
        {{ getUserName() }}
      </div>

      <div class="d-flex gap-2 justify-content-between mb-4">
        <div class="row">
          <div (click)="toNotifications()" class="w-auto pe-0">
            <img [src]="getNotificationIconUrl()" class="cursor-pointer" alt="" />
          </div>
          <div class="w-auto pe-0">
            <img
              class="cursor-pointer"
              src="assets/images/tta/settings.svg"
              (click)="toSettings()"
              alt=""
            />
          </div>
          <div class="w-auto pe-0">
            <img
              class="cursor-pointer"
              height="16px"
              width="16px"
              src="assets/images/tta/logout-gray.svg"
              (click)="authService.SignOut()"
              alt=""
            />
          </div>
        </div>

        <div (click)="switchLanguage()" class="cursor-pointer">
          <div class="lang-switcher" style="width: 60px">
            <div
              [ngClass]="{ active: currentLang == 'ru', inactive: currentLang != 'ru' }"
              class="w-50 text-center"
            >
              РУ
            </div>
            <div
              [ngClass]="{ active: currentLang == 'en', inactive: currentLang != 'en' }"
              class="w-50 text-center"
            >
              EN
            </div>
          </div>
        </div>
      </div>
      <hr class="mb-2" />

      <!-- Announcement bar navigation -->
      <div class="block-container announcement-bar">
        <div class="content-title">
          {{ 'The new book from the founder of TriAngels' | lang }}
        </div>
        <button class="btn btn-primary announcement-bar-btn" (click)="openLearningHub()">
          <div class="announcement-bar-btn-text">
            {{ 'buttons.learnMore' | lang }}
          </div>
          <img src="assets/images/tta/icons/arrow_right.svg" alt="" style="margin-left: auto" />
        </button>
      </div>
      <!-- Announcement bar end -->
      <div class="d-flex flex-column">
        <div
          *ngFor="let menuItem of menuItems"
          class="nav-item"
          [hidden]="needHidden(menuItem.roles)"
        >
          <a
            [routerLink]="!menuItem.type ? null : [menuItem.path]"
            class="nav-link p-0"
            (click)="toggletNavActive(menuItem)"
            *ngIf="menuItem.type === 'link' && !menuItem.notShowInSidebar"
          >
            <div
              [ngClass]="{ 'active-icon': menuItem.active === true }"
              class="d-flex align-items-center menu-item"
            >
              <div
                [ngClass]="{ 'active-image': menuItem.active === true }"
                class="title-hidden text-nowrap"
              >
                <img
                  src="{{ menuItem.image }}"
                  class="icon-color float-left mb-1"
                  height="24px"
                  width="24px"
                  alt=""
                />
                <span class="menu-title-container">
                  <span class="menu-asset green-transition">{{ menuItem.title | translate }}</span>
                </span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div class="w-100 mb-5">
      <div class="footer-copyright pb-2">
        <p class="f-14">©TRIANGELS {{ currentYear }}</p>
      </div>
    </div>
  </div>

  <!-- <div [hidden]="navService.showNotif">
    <ul class="nav nav-pills d-flex flex-column" style="height: calc(100% - 42px);">
      <li *ngFor="let menuItem of menuItems" class="nav-item" [hidden]="needHidden(menuItem.roles)">
        <a [routerLink]="!menuItem.type ? null : [menuItem.path]" class="nav-link p-0" (click)="toggletNavActive(menuItem)" 
           *ngIf="menuItem.type === 'link' && !menuItem.notShowInSidebar">
          <div [ngClass]="{'active-icon': menuItem.active === true}" class="d-flex align-items-center menu-item">
            <div [ngClass]="{'active-image': menuItem.active === true}" class="title-hidden text-nowrap">
              <img src="{{menuItem.image}}" class="icon-color float-left mb-1"  height="24px" width="24px">
              <span class="menu-title-container">
                <span class="menu-asset green-transition">{{menuItem.title| translate}}</span>
              </span>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div> -->
</div>
