import * as Sentry from '@sentry/angular-ivy';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  Sentry.init({
    environment: environment.envName,
    dsn: 'https://5e3ec5fd36122ec7e35fe8a58f3d6341@o120493.ingest.sentry.io/4506477709492224',
    integrations: [
      // Tracing - мониторинг взимодействия между фронтом и бэком
      new Sentry.BrowserTracing({
        // Сюда вписывается адрес бэка (на бэке необходимо разрешить определенные заголовки)
        tracePropagationTargets: [environment.apiUrl],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    // Настройки трейсинга и записей сессий
    tracesSampleRate: 1.0, // 1 - отслеживать производительность 100% транзакций
  });

  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
