import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  AuthService,
  EventService,
  StatsService,
  UserService,
  WalletService,
  HistorySessionService,
} from './shared/services';
import { CryptoUserService } from './shared/services/cryptoUser.service';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BookModal } from './shared/component/modals/book-modal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'ui14';
  intervals: any[] = [];
  modalRef: NgbModalRef;
  eventSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private modalService: NgbModal,
    private userService: UserService,
    private cryptoUserService: CryptoUserService,
    private authService: AuthService,
    private router: Router,
    private statsService: StatsService,
    private eventService: EventService,
    private walletService: WalletService,
    private historySessionService: HistorySessionService,
  ) {
    var t = this;

    // Пока не подтянутся стили и файлы отображается черный экран
    window.onload = function () {
      t.disableMockScreen();
    };
    setTimeout(t.disableMockScreen, 3000);

    t.createStyle('color-2');
    t.createStyle('table');

    t.translate.use('en');
    t.router.events.subscribe((event) => {
      let intervals: Promise<any>[] = [];

      if (t.authService.isLoggedIn) {
        if (event instanceof NavigationStart) t.historySessionService.refreshSession();

        if (!t.userService.get() && !t.eventService.isFuncArrIncludes(t.userService.refresh)) {
          intervals.push(t.userService.init());
          t.eventService.addFuncToArrayOfIntervals(t.userService.refresh, 1000 * 60 * 5);
        }

        if (
          !t.cryptoUserService.get() &&
          !t.eventService.isFuncArrIncludes(t.cryptoUserService.refresh)
        ) {
          intervals.push(t.cryptoUserService.init());
          t.eventService.addFuncToArrayOfIntervals(t.cryptoUserService.refresh, 1000 * 60 * 5);
        }

        if (
          !t.statsService.getStats() &&
          !t.eventService.isFuncArrIncludes(t.statsService.refreshStats)
        ) {
          intervals.push(t.statsService.initStats());
          t.eventService.addFuncToArrayOfIntervals(t.statsService.refreshStats, 1000 * 60 * 5);
        }

        if (
          !t.walletService.getAllBalances() &&
          !t.eventService.isFuncArrIncludes(t.walletService.refreshBalance)
        ) {
          intervals.push(t.walletService.initBalance());
          t.eventService.addFuncToArrayOfIntervals(t.walletService.refreshBalance, 1000 * 60);
        }
      }
      Promise.all(intervals).then(
        (value) => {},
        (reason) => {
          this.authService.SignOut();
        },
      );
    });
  }

  disableMockScreen() {
    var mockScreen = document.getElementById('mockScreen');
    mockScreen.style.display = 'none';
  }

  // Create style sheet append in head
  createStyle(style, preload = false) {
    var head = document.head;
    var link = document.createElement('link');
    link.type = 'text/css';
    link.rel = preload ? 'preload' : 'stylesheet';
    link.href = window.location.origin + '/assets/css/' + style + '.css';

    if (preload) {
      link.as = 'style';
      link.setAttribute('onload', "this.rel='stylesheet'");
    }

    head.appendChild(link);
  }
}
