import { Component, NgZone, OnInit, ViewChildren } from '@angular/core';
import { BaseComponent } from 'src/app/shared/component/base/base.component';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService, CryptoUserService, PublicService } from '../../shared/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidationUtils } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { docType } from 'src/app/shared/enums';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent extends BaseComponent implements OnInit {
  @ViewChildren('telegramAuthWidget') telegramAuthWidget: any;

  public registerForm: FormGroup;
  public refUser: string;
  public inviterLoaded: boolean = false;
  public inviter: string;
  public botName: string = environment.botName;
  public envName: string = environment.envName;
  public showPass: boolean = false;
  public docType = docType;
  public agreementText: string = 'I have read and agree with the Terms of use and Privacy Policy';

  constructor(
    public authService: AuthService,
    private translate: TranslateService,
    private cryptoUserService: CryptoUserService,
    private publicService: PublicService,
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private ngZone: NgZone,
  ) {
    super(translate, modalService);
    var t = this;
    t.registerForm = fb.group(
      {
        firstName: [
          '',
          [
            Validators.required,
            Validators.maxLength(50),
            Validators.minLength(2),
            Validators.pattern(t.namePattern),
          ],
        ],
        lastName: [
          '',
          [
            Validators.required,
            Validators.maxLength(50),
            Validators.minLength(2),
            Validators.pattern(t.namePattern),
          ],
        ],
        phone: ['', [Validators.required, Validators.pattern(t.phonePattern)]],
        email: [
          '',
          [Validators.required, Validators.maxLength(50), Validators.pattern(t.emailPattern)],
        ],
        password: ['', [Validators.required, Validators.pattern(t.latinAndNumberPattern)]],
        passwordConfirm: ['', [Validators.required]],
        agreement: [''],
      },
      {
        validator: [t.passwordMatchValidator, t.phoneMatchValidator],
      },
    );
  }

  get firstName() {
    return this.registerForm.get('firstName');
  }
  get lastName() {
    return this.registerForm.get('lastName');
  }
  get phone() {
    return this.registerForm.get('phone');
  }
  get email() {
    return this.registerForm.get('email');
  }
  get password() {
    return this.registerForm.get('password');
  }
  get passwordConfirm() {
    return this.registerForm.get('passwordConfirm');
  }
  get agreement() {
    return this.registerForm.get('agreement');
  }

  phoneMatchValidator(form: FormGroup) {
    var phone = form.get('phone');
    if (phone.dirty && !!phone.value) {
      phone.markAsDirty();
      phone.markAsTouched();

      var isValidPhone = ValidationUtils.validatePhone(phone.value);
      if (!isValidPhone.isValid) {
        let obj = {};
        obj[isValidPhone.errorText] = true;
        phone.setErrors(obj);
      }
    }
  }

  passwordMatchValidator(form: FormGroup) {
    var password = form.get('password');
    if (password.dirty || !!password.value) {
      password.markAsDirty();
      password.markAsTouched();
      var isValidPassword = ValidationUtils.validatePassword(password.value);
      if (!isValidPassword.isValid) {
        let obj = {};
        obj[isValidPassword.errorText] = true;
        password.setErrors(obj);
      }
    }

    var passwordConfirm = form.get('passwordConfirm');
    if (passwordConfirm.dirty || !!passwordConfirm.value) {
      passwordConfirm.markAsDirty();
      passwordConfirm.markAsTouched();
      var isValidPasswordConfirm = ValidationUtils.validatePasswordConfirmation(
        password.value,
        passwordConfirm.value,
      );
      if (!isValidPasswordConfirm.isValid) {
        let obj = {};
        obj[isValidPasswordConfirm.errorText] = true;
        passwordConfirm.setErrors(obj);
      }
      // обновить валидацию данного элемента при совпадении паролей
      else passwordConfirm.updateValueAndValidity({ onlySelf: true });
    }
  }

  ngOnInit(): void {
    (<any>window).onTelegramAuth = this.onTelegramAuth.bind(this);
    this.loadInviter();
  }

  public createTelegramRegScript() {
    if (document.getElementById('telegram-reg-script') != null) {
      return;
    }

    var size = 'large';
    var dataRadius = '20';

    const tgScript = document.createElement('script');
    tgScript.src = 'https://oauth.tg.dev/js/telegram-widget.js?21';
    tgScript.setAttribute('id', 'telegram-reg-script');
    tgScript.setAttribute('data-telegram-login', this.botName || 'samplebot');
    tgScript.setAttribute('data-size', size);
    tgScript.setAttribute('data-request-access', 'write');
    tgScript.setAttribute('data-radius', dataRadius);
    tgScript.setAttribute('data-onauth', 'onTelegramAuth(user)');
    tgScript.async = true;

    this.telegramAuthWidget.first.nativeElement.appendChild(tgScript);
  }

  onTelegramAuth(authData: any): void {
    var t = this;

    authData.first_name =
      authData.first_name != null ? encodeURIComponent(authData.first_name) : authData.first_name;

    authData.last_name =
      authData.last_name != null ? encodeURIComponent(authData.last_name) : authData.last_name;

    var authDataStr = JSON.stringify(authData);
    localStorage.setItem('telegramAuthData', authDataStr);

    var emptyCred = {
      email: '',
      password: '',
      confirmCode: '',
    };

    t.setLoading(true);
    t.authService
      .SignIn(emptyCred)
      .then(() => {
        t.setLoading(false);
        t.ngZone.run(() => t.router.navigate(['/dashboard']));
      })
      .catch((e) => {
        if (e != null && e.error != null && e.error.error == 'invalid_grant') {
          t.showError(t.translate.instant(e.error.error_description));
        } else t.showResponseError(e);

        t.setLoading(false);
      });
  }

  public loadInviter() {
    var t = this;
    var C_NAME = 'ref';
    var result;
    if (document.cookie.length > 0) {
      var C_START = document.cookie.indexOf(C_NAME + '=');

      if (C_START != -1) {
        C_START = C_START + C_NAME.length + 1;
        var C_END = document.cookie.indexOf(';', C_START);

        if (C_END == -1) {
          C_END = document.cookie.length;
        }
        result = document.cookie.substring(C_START, C_END);
      }
    }

    if (result != null && result.length >= 0) {
      t.refUser = result;
    }

    if (t.refUser) {
      t.cryptoUserService.getRefName(t.refUser).then(() => {
        t.inviter = result.data;
        t.inviterLoaded = true;
      });
    } else t.inviterLoaded = true;
  }

  public registration() {
    var t = this;

    if (t.registerForm.invalid) {
      t.markFormGroupTouchedAndDirty(t.registerForm);
      return;
    }

    if (!t.agreement.value) {
      t.showError(
        t.translate.instant(
          'Before registration, you must confirm your agreement with the Terms of Use and Privacy Policy',
        ),
      );
      return;
    }

    t.setLoading(true);
    localStorage.removeItem('telegramAuthData');

    t.publicService
      .register({
        contact: t.email.value,
        password: t.password.value,
        confirmPassword: t.passwordConfirm.value,
        captcha: (<any>window).recaptcha_code,
        refUser: t.refUser,
        localization: t.getCurrentLocalization(),
        lastName: t.lastName.value,
        firstName: t.firstName.value,
        phone: t.phone.value,
      })
      .then(() => {
        t.authService.SignIn({ email: t.email.value, password: t.password.value }).then(() => {
          t.setLoading(false);
          t.showSuccess(
            t.translate.instant('Registration completed successfully') +
              '. ' +
              t.translate.instant('Activation link has has been sent to your e-mail') +
              ': ' +
              t.email.value,
            'System',
          );
          t.router.navigate(['/auth/login']);
        });
      })
      .catch((e) => {
        t.setLoading(false);
        t.showResponseError(e);
      });
  }

  public navigateTab(route: string) {
    var t = this;
    t.router.navigateByUrl('/auth/' + route);
  }
}
