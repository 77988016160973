<div
  *ngIf="!environment.buildAdmin"
  class="auth-bg bg-sign"
  [ngClass]="{ 'bg-reg': subpagesMenu[1].isActive }"
>
  <div class="align-center">
    <img class="h-45" src="assets/images/logo/tta_logo.svg" />
  </div>
  <div class="auth-col-width">
    <div class="authentication-box">
      <app-login *ngIf="subpagesMenu[0].isActive"></app-login>
      <app-register *ngIf="subpagesMenu[1].isActive"></app-register>
      <confirmation-code *ngIf="subpagesMenu[2].isActive"></confirmation-code>
      <app-resetpass *ngIf="subpagesMenu[3].isActive"></app-resetpass>
      <div class="d-flex justify-content-around align-center pt-3">
        <div
          class="lang-switcher align-center cursor-pointer"
          style="width: 60px"
          (click)="switchLanguage()"
        >
          <div
            [ngClass]="{ active: currentLang == 'ru', inactive: currentLang != 'ru' }"
            class="w-50 t-center"
          >
            РУ
          </div>
          <div
            [ngClass]="{ active: currentLang == 'en', inactive: currentLang != 'en' }"
            class="w-50 t-center"
          >
            EN
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="align-center-900 c-gray-semi-light text-center">© TRIANGELS {{ currentYear }}</div>
</div>

<div
  *ngIf="environment.buildAdmin"
  class="auth-bg auth-bg-admin align-items-center"
  style="background-size: 100% !important"
>
  <img class="h-32" src="assets/images/logo/tta_logo.svg" />
  <div class="authentication-box">
    <app-admin-login *ngIf="subpagesMenu[0].isActive"></app-admin-login>
  </div>
  <div class="align-center c-gray-semi-dark">©TRIANGELS {{ currentYear }}</div>
</div>
